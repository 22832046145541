/*--
    - Custom Row
------------------------------------------*/
.row-0 {
  margin-left: 0px;
  margin-right: 0px; }
  .row-0 > [class*="col"] {
    padding-left: 0px;
    padding-right: 0px; }

.row-1 {
  margin-left: -1px;
  margin-right: -1px; }
  .row-1 > [class*="col"] {
    padding-left: 1px;
    padding-right: 1px; }

.row-2 {
  margin-left: -2px;
  margin-right: -2px; }
  .row-2 > [class*="col"] {
    padding-left: 2px;
    padding-right: 2px; }

.row-3 {
  margin-left: -3px;
  margin-right: -3px; }
  .row-3 > [class*="col"] {
    padding-left: 3px;
    padding-right: 3px; }

.row-4 {
  margin-left: -4px;
  margin-right: -4px; }
  .row-4 > [class*="col"] {
    padding-left: 4px;
    padding-right: 4px; }

.row-5 {
  margin-left: -5px;
  margin-right: -5px; }
  .row-5 > [class*="col"] {
    padding-left: 5px;
    padding-right: 5px; }

.row-6 {
  margin-left: -6px;
  margin-right: -6px; }
  .row-6 > [class*="col"] {
    padding-left: 6px;
    padding-right: 6px; }

.row-7 {
  margin-left: -7px;
  margin-right: -7px; }
  .row-7 > [class*="col"] {
    padding-left: 7px;
    padding-right: 7px; }

.row-8 {
  margin-left: -8px;
  margin-right: -8px; }
  .row-8 > [class*="col"] {
    padding-left: 8px;
    padding-right: 8px; }

.row-9 {
  margin-left: -9px;
  margin-right: -9px; }
  .row-9 > [class*="col"] {
    padding-left: 9px;
    padding-right: 9px; }

.row-10 {
  margin-left: -10px;
  margin-right: -10px; }
  .row-10 > [class*="col"] {
    padding-left: 10px;
    padding-right: 10px; }

.row-11 {
  margin-left: -11px;
  margin-right: -11px; }
  .row-11 > [class*="col"] {
    padding-left: 11px;
    padding-right: 11px; }

.row-12 {
  margin-left: -12px;
  margin-right: -12px; }
  .row-12 > [class*="col"] {
    padding-left: 12px;
    padding-right: 12px; }

.row-13 {
  margin-left: -13px;
  margin-right: -13px; }
  .row-13 > [class*="col"] {
    padding-left: 13px;
    padding-right: 13px; }

.row-14 {
  margin-left: -14px;
  margin-right: -14px; }
  .row-14 > [class*="col"] {
    padding-left: 14px;
    padding-right: 14px; }

.row-15 {
  margin-left: -15px;
  margin-right: -15px; }
  .row-15 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px; }

.row-16 {
  margin-left: -16px;
  margin-right: -16px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-16 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-16 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-16 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-16 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-16 > [class*="col"] {
    padding-left: 16px;
    padding-right: 16px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-16 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-16 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-16 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-16 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-17 {
  margin-left: -17px;
  margin-right: -17px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-17 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-17 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-17 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-17 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-17 > [class*="col"] {
    padding-left: 17px;
    padding-right: 17px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-17 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-17 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-17 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-17 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-18 {
  margin-left: -18px;
  margin-right: -18px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-18 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-18 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-18 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-18 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-18 > [class*="col"] {
    padding-left: 18px;
    padding-right: 18px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-18 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-18 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-18 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-18 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-19 {
  margin-left: -19px;
  margin-right: -19px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-19 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-19 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-19 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-19 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-19 > [class*="col"] {
    padding-left: 19px;
    padding-right: 19px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-19 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-19 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-19 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-19 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-20 {
  margin-left: -20px;
  margin-right: -20px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-20 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-20 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-20 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-20 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-20 > [class*="col"] {
    padding-left: 20px;
    padding-right: 20px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-20 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-20 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-20 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-20 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-21 {
  margin-left: -21px;
  margin-right: -21px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-21 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-21 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-21 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-21 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-21 > [class*="col"] {
    padding-left: 21px;
    padding-right: 21px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-21 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-21 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-21 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-21 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-22 {
  margin-left: -22px;
  margin-right: -22px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-22 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-22 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-22 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-22 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-22 > [class*="col"] {
    padding-left: 22px;
    padding-right: 22px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-22 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-22 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-22 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-22 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-23 {
  margin-left: -23px;
  margin-right: -23px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-23 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-23 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-23 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-23 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-23 > [class*="col"] {
    padding-left: 23px;
    padding-right: 23px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-23 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-23 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-23 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-23 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-24 {
  margin-left: -24px;
  margin-right: -24px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-24 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-24 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-24 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-24 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-24 > [class*="col"] {
    padding-left: 24px;
    padding-right: 24px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-24 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-24 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-24 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-24 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-25 {
  margin-left: -25px;
  margin-right: -25px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-25 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-25 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-25 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-25 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-25 > [class*="col"] {
    padding-left: 25px;
    padding-right: 25px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-25 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-25 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-25 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-25 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-26 {
  margin-left: -26px;
  margin-right: -26px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-26 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-26 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-26 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-26 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-26 > [class*="col"] {
    padding-left: 26px;
    padding-right: 26px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-26 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-26 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-26 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-26 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-27 {
  margin-left: -27px;
  margin-right: -27px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-27 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-27 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-27 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-27 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-27 > [class*="col"] {
    padding-left: 27px;
    padding-right: 27px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-27 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-27 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-27 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-27 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-28 {
  margin-left: -28px;
  margin-right: -28px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-28 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-28 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-28 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-28 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-28 > [class*="col"] {
    padding-left: 28px;
    padding-right: 28px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-28 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-28 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-28 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-28 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-29 {
  margin-left: -29px;
  margin-right: -29px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-29 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-29 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-29 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-29 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-29 > [class*="col"] {
    padding-left: 29px;
    padding-right: 29px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-29 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-29 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-29 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-29 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-30 {
  margin-left: -30px;
  margin-right: -30px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-30 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-30 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-30 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-30 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-30 > [class*="col"] {
    padding-left: 30px;
    padding-right: 30px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-30 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-30 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-30 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-30 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-31 {
  margin-left: -31px;
  margin-right: -31px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-31 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-31 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-31 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-31 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-31 > [class*="col"] {
    padding-left: 31px;
    padding-right: 31px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-31 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-31 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-31 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-31 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-32 {
  margin-left: -32px;
  margin-right: -32px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-32 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-32 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-32 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-32 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-32 > [class*="col"] {
    padding-left: 32px;
    padding-right: 32px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-32 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-32 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-32 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-32 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-33 {
  margin-left: -33px;
  margin-right: -33px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-33 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-33 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-33 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-33 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-33 > [class*="col"] {
    padding-left: 33px;
    padding-right: 33px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-33 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-33 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-33 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-33 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-34 {
  margin-left: -34px;
  margin-right: -34px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-34 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-34 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-34 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-34 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-34 > [class*="col"] {
    padding-left: 34px;
    padding-right: 34px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-34 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-34 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-34 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-34 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-35 {
  margin-left: -35px;
  margin-right: -35px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-35 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-35 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-35 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-35 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-35 > [class*="col"] {
    padding-left: 35px;
    padding-right: 35px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-35 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-35 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-35 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-35 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-36 {
  margin-left: -36px;
  margin-right: -36px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-36 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-36 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-36 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-36 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-36 > [class*="col"] {
    padding-left: 36px;
    padding-right: 36px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-36 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-36 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-36 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-36 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-37 {
  margin-left: -37px;
  margin-right: -37px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-37 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-37 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-37 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-37 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-37 > [class*="col"] {
    padding-left: 37px;
    padding-right: 37px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-37 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-37 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-37 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-37 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-38 {
  margin-left: -38px;
  margin-right: -38px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-38 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-38 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-38 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-38 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-38 > [class*="col"] {
    padding-left: 38px;
    padding-right: 38px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-38 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-38 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-38 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-38 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-39 {
  margin-left: -39px;
  margin-right: -39px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-39 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-39 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-39 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-39 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-39 > [class*="col"] {
    padding-left: 39px;
    padding-right: 39px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-39 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-39 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-39 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-39 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-40 {
  margin-left: -40px;
  margin-right: -40px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-40 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-40 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-40 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-40 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-40 > [class*="col"] {
    padding-left: 40px;
    padding-right: 40px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-40 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-40 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-40 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-40 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-41 {
  margin-left: -41px;
  margin-right: -41px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-41 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-41 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-41 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-41 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-41 > [class*="col"] {
    padding-left: 41px;
    padding-right: 41px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-41 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-41 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-41 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-41 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-42 {
  margin-left: -42px;
  margin-right: -42px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-42 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-42 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-42 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-42 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-42 > [class*="col"] {
    padding-left: 42px;
    padding-right: 42px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-42 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-42 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-42 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-42 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-43 {
  margin-left: -43px;
  margin-right: -43px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-43 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-43 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-43 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-43 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-43 > [class*="col"] {
    padding-left: 43px;
    padding-right: 43px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-43 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-43 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-43 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-43 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-44 {
  margin-left: -44px;
  margin-right: -44px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-44 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-44 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-44 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-44 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-44 > [class*="col"] {
    padding-left: 44px;
    padding-right: 44px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-44 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-44 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-44 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-44 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-45 {
  margin-left: -45px;
  margin-right: -45px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-45 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-45 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-45 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-45 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-45 > [class*="col"] {
    padding-left: 45px;
    padding-right: 45px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-45 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-45 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-45 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-45 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-46 {
  margin-left: -46px;
  margin-right: -46px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-46 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-46 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-46 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-46 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-46 > [class*="col"] {
    padding-left: 46px;
    padding-right: 46px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-46 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-46 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-46 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-46 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-47 {
  margin-left: -47px;
  margin-right: -47px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-47 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-47 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-47 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-47 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-47 > [class*="col"] {
    padding-left: 47px;
    padding-right: 47px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-47 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-47 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-47 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-47 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-48 {
  margin-left: -48px;
  margin-right: -48px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-48 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-48 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-48 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-48 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-48 > [class*="col"] {
    padding-left: 48px;
    padding-right: 48px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-48 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-48 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-48 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-48 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-49 {
  margin-left: -49px;
  margin-right: -49px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-49 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-49 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-49 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-49 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-49 > [class*="col"] {
    padding-left: 49px;
    padding-right: 49px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-49 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-49 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-49 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-49 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-50 {
  margin-left: -50px;
  margin-right: -50px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-50 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-50 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-50 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-50 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-50 > [class*="col"] {
    padding-left: 50px;
    padding-right: 50px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-50 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-50 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-50 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-50 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-51 {
  margin-left: -51px;
  margin-right: -51px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-51 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-51 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-51 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-51 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-51 > [class*="col"] {
    padding-left: 51px;
    padding-right: 51px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-51 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-51 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-51 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-51 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-52 {
  margin-left: -52px;
  margin-right: -52px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-52 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-52 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-52 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-52 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-52 > [class*="col"] {
    padding-left: 52px;
    padding-right: 52px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-52 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-52 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-52 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-52 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-53 {
  margin-left: -53px;
  margin-right: -53px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-53 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-53 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-53 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-53 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-53 > [class*="col"] {
    padding-left: 53px;
    padding-right: 53px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-53 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-53 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-53 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-53 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-54 {
  margin-left: -54px;
  margin-right: -54px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-54 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-54 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-54 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-54 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-54 > [class*="col"] {
    padding-left: 54px;
    padding-right: 54px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-54 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-54 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-54 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-54 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-55 {
  margin-left: -55px;
  margin-right: -55px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-55 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-55 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-55 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-55 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-55 > [class*="col"] {
    padding-left: 55px;
    padding-right: 55px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-55 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-55 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-55 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-55 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-56 {
  margin-left: -56px;
  margin-right: -56px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-56 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-56 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-56 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-56 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-56 > [class*="col"] {
    padding-left: 56px;
    padding-right: 56px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-56 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-56 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-56 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-56 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-57 {
  margin-left: -57px;
  margin-right: -57px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-57 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-57 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-57 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-57 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-57 > [class*="col"] {
    padding-left: 57px;
    padding-right: 57px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-57 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-57 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-57 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-57 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-58 {
  margin-left: -58px;
  margin-right: -58px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-58 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-58 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-58 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-58 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-58 > [class*="col"] {
    padding-left: 58px;
    padding-right: 58px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-58 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-58 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-58 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-58 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-59 {
  margin-left: -59px;
  margin-right: -59px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-59 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-59 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-59 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-59 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-59 > [class*="col"] {
    padding-left: 59px;
    padding-right: 59px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-59 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-59 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-59 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-59 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-60 {
  margin-left: -60px;
  margin-right: -60px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-60 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-60 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-60 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-60 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-60 > [class*="col"] {
    padding-left: 60px;
    padding-right: 60px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-60 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-60 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-60 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-60 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-61 {
  margin-left: -61px;
  margin-right: -61px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-61 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-61 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-61 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-61 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-61 > [class*="col"] {
    padding-left: 61px;
    padding-right: 61px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-61 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-61 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-61 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-61 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-62 {
  margin-left: -62px;
  margin-right: -62px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-62 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-62 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-62 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-62 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-62 > [class*="col"] {
    padding-left: 62px;
    padding-right: 62px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-62 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-62 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-62 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-62 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-63 {
  margin-left: -63px;
  margin-right: -63px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-63 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-63 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-63 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-63 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-63 > [class*="col"] {
    padding-left: 63px;
    padding-right: 63px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-63 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-63 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-63 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-63 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-64 {
  margin-left: -64px;
  margin-right: -64px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-64 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-64 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-64 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-64 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-64 > [class*="col"] {
    padding-left: 64px;
    padding-right: 64px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-64 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-64 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-64 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-64 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-65 {
  margin-left: -65px;
  margin-right: -65px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-65 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-65 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-65 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-65 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-65 > [class*="col"] {
    padding-left: 65px;
    padding-right: 65px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-65 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-65 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-65 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-65 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-66 {
  margin-left: -66px;
  margin-right: -66px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-66 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-66 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-66 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-66 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-66 > [class*="col"] {
    padding-left: 66px;
    padding-right: 66px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-66 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-66 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-66 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-66 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-67 {
  margin-left: -67px;
  margin-right: -67px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-67 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-67 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-67 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-67 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-67 > [class*="col"] {
    padding-left: 67px;
    padding-right: 67px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-67 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-67 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-67 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-67 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-68 {
  margin-left: -68px;
  margin-right: -68px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-68 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-68 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-68 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-68 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-68 > [class*="col"] {
    padding-left: 68px;
    padding-right: 68px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-68 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-68 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-68 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-68 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-69 {
  margin-left: -69px;
  margin-right: -69px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-69 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-69 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-69 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-69 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-69 > [class*="col"] {
    padding-left: 69px;
    padding-right: 69px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-69 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-69 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-69 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-69 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-70 {
  margin-left: -70px;
  margin-right: -70px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-70 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-70 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-70 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-70 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-70 > [class*="col"] {
    padding-left: 70px;
    padding-right: 70px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-70 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-70 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-70 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-70 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-71 {
  margin-left: -71px;
  margin-right: -71px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-71 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-71 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-71 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-71 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-71 > [class*="col"] {
    padding-left: 71px;
    padding-right: 71px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-71 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-71 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-71 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-71 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-72 {
  margin-left: -72px;
  margin-right: -72px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-72 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-72 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-72 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-72 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-72 > [class*="col"] {
    padding-left: 72px;
    padding-right: 72px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-72 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-72 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-72 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-72 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-73 {
  margin-left: -73px;
  margin-right: -73px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-73 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-73 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-73 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-73 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-73 > [class*="col"] {
    padding-left: 73px;
    padding-right: 73px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-73 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-73 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-73 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-73 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-74 {
  margin-left: -74px;
  margin-right: -74px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-74 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-74 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-74 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-74 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-74 > [class*="col"] {
    padding-left: 74px;
    padding-right: 74px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-74 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-74 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-74 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-74 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-75 {
  margin-left: -75px;
  margin-right: -75px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-75 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-75 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-75 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-75 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-75 > [class*="col"] {
    padding-left: 75px;
    padding-right: 75px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-75 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-75 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-75 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-75 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-76 {
  margin-left: -76px;
  margin-right: -76px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-76 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-76 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-76 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-76 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-76 > [class*="col"] {
    padding-left: 76px;
    padding-right: 76px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-76 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-76 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-76 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-76 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-77 {
  margin-left: -77px;
  margin-right: -77px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-77 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-77 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-77 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-77 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-77 > [class*="col"] {
    padding-left: 77px;
    padding-right: 77px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-77 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-77 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-77 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-77 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-78 {
  margin-left: -78px;
  margin-right: -78px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-78 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-78 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-78 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-78 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-78 > [class*="col"] {
    padding-left: 78px;
    padding-right: 78px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-78 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-78 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-78 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-78 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-79 {
  margin-left: -79px;
  margin-right: -79px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-79 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-79 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-79 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-79 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-79 > [class*="col"] {
    padding-left: 79px;
    padding-right: 79px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-79 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-79 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-79 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-79 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-80 {
  margin-left: -80px;
  margin-right: -80px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-80 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-80 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-80 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-80 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-80 > [class*="col"] {
    padding-left: 80px;
    padding-right: 80px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-80 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-80 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-80 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-80 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

/*-- 
    - Margin & Padding
-----------------------------------------*/
/*-- Margin Top --*/
.mt-0 {
  margin-top: 0px !important; }

.mt-5 {
  margin-top: 5px !important; }

.mt-10 {
  margin-top: 10px !important; }

.mt-15 {
  margin-top: 15px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mt-25 {
  margin-top: 25px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mt-35 {
  margin-top: 35px !important; }

.mt-40 {
  margin-top: 40px !important; }

.mt-45 {
  margin-top: 45px !important; }

.mt-50 {
  margin-top: 50px !important; }

.mt-55 {
  margin-top: 55px !important; }

.mt-60 {
  margin-top: 60px !important; }

.mt-65 {
  margin-top: 65px !important; }

.mt-70 {
  margin-top: 70px !important; }

.mt-75 {
  margin-top: 75px !important; }

.mt-80 {
  margin-top: 80px !important; }

.mt-85 {
  margin-top: 85px !important; }

.mt-90 {
  margin-top: 90px !important; }

.mt-95 {
  margin-top: 95px !important; }

.mt-100 {
  margin-top: 100px !important; }

.mt-105 {
  margin-top: 105px !important; }

.mt-110 {
  margin-top: 110px !important; }

.mt-115 {
  margin-top: 115px !important; }

.mt-120 {
  margin-top: 120px !important; }

.mt-125 {
  margin-top: 125px !important; }

.mt-130 {
  margin-top: 130px !important; }

.mt-135 {
  margin-top: 135px !important; }

.mt-140 {
  margin-top: 140px !important; }

.mt-145 {
  margin-top: 145px !important; }

.mt-150 {
  margin-top: 150px !important; }

.mt-155 {
  margin-top: 155px !important; }

.mt-160 {
  margin-top: 160px !important; }

.mt-165 {
  margin-top: 165px !important; }

.mt-170 {
  margin-top: 170px !important; }

.mt-175 {
  margin-top: 175px !important; }

.mt-180 {
  margin-top: 180px !important; }

.mt-185 {
  margin-top: 185px !important; }

.mt-190 {
  margin-top: 190px !important; }

.mt-195 {
  margin-top: 195px !important; }

.mt-200 {
  margin-top: 200px !important; }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-0 {
    margin-top: 0px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-5 {
    margin-top: 5px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-10 {
    margin-top: 10px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-15 {
    margin-top: 15px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-20 {
    margin-top: 20px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-25 {
    margin-top: 25px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-30 {
    margin-top: 30px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-35 {
    margin-top: 35px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-40 {
    margin-top: 40px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-45 {
    margin-top: 45px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-50 {
    margin-top: 50px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-55 {
    margin-top: 55px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-60 {
    margin-top: 60px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-65 {
    margin-top: 65px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-70 {
    margin-top: 70px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-75 {
    margin-top: 75px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-80 {
    margin-top: 80px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-85 {
    margin-top: 85px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-90 {
    margin-top: 90px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-95 {
    margin-top: 95px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-100 {
    margin-top: 100px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-105 {
    margin-top: 105px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-110 {
    margin-top: 110px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-115 {
    margin-top: 115px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-120 {
    margin-top: 120px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-125 {
    margin-top: 125px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-130 {
    margin-top: 130px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-135 {
    margin-top: 135px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-140 {
    margin-top: 140px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-145 {
    margin-top: 145px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-150 {
    margin-top: 150px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-155 {
    margin-top: 155px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-160 {
    margin-top: 160px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-165 {
    margin-top: 165px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-170 {
    margin-top: 170px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-175 {
    margin-top: 175px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-180 {
    margin-top: 180px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-185 {
    margin-top: 185px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-190 {
    margin-top: 190px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-195 {
    margin-top: 195px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-200 {
    margin-top: 200px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-0 {
    margin-top: 0px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-5 {
    margin-top: 5px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-10 {
    margin-top: 10px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-15 {
    margin-top: 15px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-20 {
    margin-top: 20px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-25 {
    margin-top: 25px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-30 {
    margin-top: 30px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-35 {
    margin-top: 35px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-40 {
    margin-top: 40px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-45 {
    margin-top: 45px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-50 {
    margin-top: 50px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-55 {
    margin-top: 55px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-60 {
    margin-top: 60px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-65 {
    margin-top: 65px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-70 {
    margin-top: 70px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-75 {
    margin-top: 75px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-80 {
    margin-top: 80px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-85 {
    margin-top: 85px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-90 {
    margin-top: 90px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-95 {
    margin-top: 95px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-100 {
    margin-top: 100px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-105 {
    margin-top: 105px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-110 {
    margin-top: 110px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-115 {
    margin-top: 115px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-120 {
    margin-top: 120px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-125 {
    margin-top: 125px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-130 {
    margin-top: 130px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-135 {
    margin-top: 135px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-140 {
    margin-top: 140px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-145 {
    margin-top: 145px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-150 {
    margin-top: 150px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-155 {
    margin-top: 155px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-160 {
    margin-top: 160px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-165 {
    margin-top: 165px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-170 {
    margin-top: 170px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-175 {
    margin-top: 175px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-180 {
    margin-top: 180px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-185 {
    margin-top: 185px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-190 {
    margin-top: 190px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-195 {
    margin-top: 195px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-200 {
    margin-top: 200px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-0 {
    margin-top: 0px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-5 {
    margin-top: 5px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-10 {
    margin-top: 10px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-15 {
    margin-top: 15px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-20 {
    margin-top: 20px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-25 {
    margin-top: 25px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-30 {
    margin-top: 30px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-35 {
    margin-top: 35px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-40 {
    margin-top: 40px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-45 {
    margin-top: 45px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-50 {
    margin-top: 50px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-55 {
    margin-top: 55px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-60 {
    margin-top: 60px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-65 {
    margin-top: 65px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-70 {
    margin-top: 70px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-75 {
    margin-top: 75px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-80 {
    margin-top: 80px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-85 {
    margin-top: 85px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-90 {
    margin-top: 90px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-95 {
    margin-top: 95px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-100 {
    margin-top: 100px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-105 {
    margin-top: 105px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-110 {
    margin-top: 110px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-115 {
    margin-top: 115px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-120 {
    margin-top: 120px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-125 {
    margin-top: 125px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-130 {
    margin-top: 130px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-135 {
    margin-top: 135px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-140 {
    margin-top: 140px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-145 {
    margin-top: 145px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-150 {
    margin-top: 150px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-155 {
    margin-top: 155px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-160 {
    margin-top: 160px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-165 {
    margin-top: 165px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-170 {
    margin-top: 170px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-175 {
    margin-top: 175px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-180 {
    margin-top: 180px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-185 {
    margin-top: 185px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-190 {
    margin-top: 190px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-195 {
    margin-top: 195px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-200 {
    margin-top: 200px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-0 {
    margin-top: 0px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-5 {
    margin-top: 5px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-10 {
    margin-top: 10px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-15 {
    margin-top: 15px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-20 {
    margin-top: 20px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-25 {
    margin-top: 25px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-30 {
    margin-top: 30px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-35 {
    margin-top: 35px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-40 {
    margin-top: 40px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-45 {
    margin-top: 45px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-50 {
    margin-top: 50px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-55 {
    margin-top: 55px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-60 {
    margin-top: 60px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-65 {
    margin-top: 65px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-70 {
    margin-top: 70px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-75 {
    margin-top: 75px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-80 {
    margin-top: 80px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-85 {
    margin-top: 85px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-90 {
    margin-top: 90px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-95 {
    margin-top: 95px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-100 {
    margin-top: 100px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-105 {
    margin-top: 105px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-110 {
    margin-top: 110px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-115 {
    margin-top: 115px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-120 {
    margin-top: 120px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-125 {
    margin-top: 125px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-130 {
    margin-top: 130px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-135 {
    margin-top: 135px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-140 {
    margin-top: 140px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-145 {
    margin-top: 145px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-150 {
    margin-top: 150px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-155 {
    margin-top: 155px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-160 {
    margin-top: 160px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-165 {
    margin-top: 165px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-170 {
    margin-top: 170px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-175 {
    margin-top: 175px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-180 {
    margin-top: 180px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-185 {
    margin-top: 185px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-190 {
    margin-top: 190px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-195 {
    margin-top: 195px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-200 {
    margin-top: 200px !important; } }

/*-- Margin Bottom --*/
.mb-0 {
  margin-bottom: 0px !important; }

.mb-5 {
  margin-bottom: 5px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.mb-25 {
  margin-bottom: 25px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.mb-35 {
  margin-bottom: 35px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.mb-45 {
  margin-bottom: 45px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.mb-55 {
  margin-bottom: 55px !important; }

.mb-60 {
  margin-bottom: 60px !important; }

.mb-65 {
  margin-bottom: 65px !important; }

.mb-70 {
  margin-bottom: 70px !important; }

.mb-75 {
  margin-bottom: 75px !important; }

.mb-80 {
  margin-bottom: 80px !important; }

.mb-85 {
  margin-bottom: 85px !important; }

.mb-90 {
  margin-bottom: 90px !important; }

.mb-95 {
  margin-bottom: 95px !important; }

.mb-100 {
  margin-bottom: 100px !important; }

.mb-105 {
  margin-bottom: 105px !important; }

.mb-110 {
  margin-bottom: 110px !important; }

.mb-115 {
  margin-bottom: 115px !important; }

.mb-120 {
  margin-bottom: 120px !important; }

.mb-125 {
  margin-bottom: 125px !important; }

.mb-130 {
  margin-bottom: 130px !important; }

.mb-135 {
  margin-bottom: 135px !important; }

.mb-140 {
  margin-bottom: 140px !important; }

.mb-145 {
  margin-bottom: 145px !important; }

.mb-150 {
  margin-bottom: 150px !important; }

.mb-155 {
  margin-bottom: 155px !important; }

.mb-160 {
  margin-bottom: 160px !important; }

.mb-165 {
  margin-bottom: 165px !important; }

.mb-170 {
  margin-bottom: 170px !important; }

.mb-175 {
  margin-bottom: 175px !important; }

.mb-180 {
  margin-bottom: 180px !important; }

.mb-185 {
  margin-bottom: 185px !important; }

.mb-190 {
  margin-bottom: 190px !important; }

.mb-195 {
  margin-bottom: 195px !important; }

.mb-200 {
  margin-bottom: 200px !important; }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-0 {
    margin-bottom: 0px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-5 {
    margin-bottom: 5px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-10 {
    margin-bottom: 10px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-15 {
    margin-bottom: 15px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-20 {
    margin-bottom: 20px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-25 {
    margin-bottom: 25px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-30 {
    margin-bottom: 30px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-35 {
    margin-bottom: 35px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-40 {
    margin-bottom: 40px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-45 {
    margin-bottom: 45px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-50 {
    margin-bottom: 50px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-55 {
    margin-bottom: 55px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-60 {
    margin-bottom: 60px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-65 {
    margin-bottom: 65px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-70 {
    margin-bottom: 70px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-75 {
    margin-bottom: 75px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-80 {
    margin-bottom: 80px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-85 {
    margin-bottom: 85px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-90 {
    margin-bottom: 90px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-95 {
    margin-bottom: 95px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-100 {
    margin-bottom: 100px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-105 {
    margin-bottom: 105px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-110 {
    margin-bottom: 110px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-115 {
    margin-bottom: 115px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-120 {
    margin-bottom: 120px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-125 {
    margin-bottom: 125px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-130 {
    margin-bottom: 130px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-135 {
    margin-bottom: 135px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-140 {
    margin-bottom: 140px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-145 {
    margin-bottom: 145px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-150 {
    margin-bottom: 150px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-155 {
    margin-bottom: 155px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-160 {
    margin-bottom: 160px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-165 {
    margin-bottom: 165px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-170 {
    margin-bottom: 170px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-175 {
    margin-bottom: 175px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-180 {
    margin-bottom: 180px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-185 {
    margin-bottom: 185px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-190 {
    margin-bottom: 190px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-195 {
    margin-bottom: 195px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-200 {
    margin-bottom: 200px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-0 {
    margin-bottom: 0px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-5 {
    margin-bottom: 5px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-10 {
    margin-bottom: 10px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-15 {
    margin-bottom: 15px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-20 {
    margin-bottom: 20px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-25 {
    margin-bottom: 25px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-30 {
    margin-bottom: 30px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-35 {
    margin-bottom: 35px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-40 {
    margin-bottom: 40px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-45 {
    margin-bottom: 45px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-50 {
    margin-bottom: 50px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-55 {
    margin-bottom: 55px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-60 {
    margin-bottom: 60px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-65 {
    margin-bottom: 65px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-70 {
    margin-bottom: 70px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-75 {
    margin-bottom: 75px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-80 {
    margin-bottom: 80px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-85 {
    margin-bottom: 85px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-90 {
    margin-bottom: 90px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-95 {
    margin-bottom: 95px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-100 {
    margin-bottom: 100px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-105 {
    margin-bottom: 105px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-110 {
    margin-bottom: 110px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-115 {
    margin-bottom: 115px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-120 {
    margin-bottom: 120px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-125 {
    margin-bottom: 125px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-130 {
    margin-bottom: 130px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-135 {
    margin-bottom: 135px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-140 {
    margin-bottom: 140px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-145 {
    margin-bottom: 145px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-150 {
    margin-bottom: 150px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-155 {
    margin-bottom: 155px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-160 {
    margin-bottom: 160px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-165 {
    margin-bottom: 165px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-170 {
    margin-bottom: 170px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-175 {
    margin-bottom: 175px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-180 {
    margin-bottom: 180px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-185 {
    margin-bottom: 185px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-190 {
    margin-bottom: 190px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-195 {
    margin-bottom: 195px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-200 {
    margin-bottom: 200px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-0 {
    margin-bottom: 0px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-5 {
    margin-bottom: 5px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-10 {
    margin-bottom: 10px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-15 {
    margin-bottom: 15px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-20 {
    margin-bottom: 20px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-25 {
    margin-bottom: 25px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-30 {
    margin-bottom: 30px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-35 {
    margin-bottom: 35px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-40 {
    margin-bottom: 40px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-45 {
    margin-bottom: 45px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-50 {
    margin-bottom: 50px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-55 {
    margin-bottom: 55px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-60 {
    margin-bottom: 60px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-65 {
    margin-bottom: 65px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-70 {
    margin-bottom: 70px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-75 {
    margin-bottom: 75px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-80 {
    margin-bottom: 80px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-85 {
    margin-bottom: 85px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-90 {
    margin-bottom: 90px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-95 {
    margin-bottom: 95px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-100 {
    margin-bottom: 100px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-105 {
    margin-bottom: 105px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-110 {
    margin-bottom: 110px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-115 {
    margin-bottom: 115px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-120 {
    margin-bottom: 120px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-125 {
    margin-bottom: 125px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-130 {
    margin-bottom: 130px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-135 {
    margin-bottom: 135px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-140 {
    margin-bottom: 140px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-145 {
    margin-bottom: 145px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-150 {
    margin-bottom: 150px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-155 {
    margin-bottom: 155px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-160 {
    margin-bottom: 160px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-165 {
    margin-bottom: 165px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-170 {
    margin-bottom: 170px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-175 {
    margin-bottom: 175px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-180 {
    margin-bottom: 180px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-185 {
    margin-bottom: 185px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-190 {
    margin-bottom: 190px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-195 {
    margin-bottom: 195px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-200 {
    margin-bottom: 200px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-0 {
    margin-bottom: 0px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-5 {
    margin-bottom: 5px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-10 {
    margin-bottom: 10px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-15 {
    margin-bottom: 15px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-20 {
    margin-bottom: 20px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-25 {
    margin-bottom: 25px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-30 {
    margin-bottom: 30px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-35 {
    margin-bottom: 35px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-40 {
    margin-bottom: 40px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-45 {
    margin-bottom: 45px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-50 {
    margin-bottom: 50px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-55 {
    margin-bottom: 55px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-60 {
    margin-bottom: 60px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-65 {
    margin-bottom: 65px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-70 {
    margin-bottom: 70px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-75 {
    margin-bottom: 75px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-80 {
    margin-bottom: 80px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-85 {
    margin-bottom: 85px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-90 {
    margin-bottom: 90px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-95 {
    margin-bottom: 95px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-100 {
    margin-bottom: 100px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-105 {
    margin-bottom: 105px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-110 {
    margin-bottom: 110px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-115 {
    margin-bottom: 115px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-120 {
    margin-bottom: 120px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-125 {
    margin-bottom: 125px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-130 {
    margin-bottom: 130px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-135 {
    margin-bottom: 135px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-140 {
    margin-bottom: 140px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-145 {
    margin-bottom: 145px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-150 {
    margin-bottom: 150px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-155 {
    margin-bottom: 155px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-160 {
    margin-bottom: 160px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-165 {
    margin-bottom: 165px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-170 {
    margin-bottom: 170px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-175 {
    margin-bottom: 175px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-180 {
    margin-bottom: 180px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-185 {
    margin-bottom: 185px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-190 {
    margin-bottom: 190px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-195 {
    margin-bottom: 195px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-200 {
    margin-bottom: 200px !important; } }

/*-- Margin Left --*/
.ml-0 {
  margin-left: 0px !important; }

.ml-5 {
  margin-left: 5px !important; }

.ml-10 {
  margin-left: 10px !important; }

.ml-15 {
  margin-left: 15px !important; }

.ml-20 {
  margin-left: 20px !important; }

.ml-25 {
  margin-left: 25px !important; }

.ml-30 {
  margin-left: 30px !important; }

.ml-35 {
  margin-left: 35px !important; }

.ml-40 {
  margin-left: 40px !important; }

.ml-45 {
  margin-left: 45px !important; }

.ml-50 {
  margin-left: 50px !important; }

.ml-55 {
  margin-left: 55px !important; }

.ml-60 {
  margin-left: 60px !important; }

.ml-65 {
  margin-left: 65px !important; }

.ml-70 {
  margin-left: 70px !important; }

.ml-75 {
  margin-left: 75px !important; }

.ml-80 {
  margin-left: 80px !important; }

.ml-85 {
  margin-left: 85px !important; }

.ml-90 {
  margin-left: 90px !important; }

.ml-95 {
  margin-left: 95px !important; }

.ml-100 {
  margin-left: 100px !important; }

.ml-105 {
  margin-left: 105px !important; }

.ml-110 {
  margin-left: 110px !important; }

.ml-115 {
  margin-left: 115px !important; }

.ml-120 {
  margin-left: 120px !important; }

.ml-125 {
  margin-left: 125px !important; }

.ml-130 {
  margin-left: 130px !important; }

.ml-135 {
  margin-left: 135px !important; }

.ml-140 {
  margin-left: 140px !important; }

.ml-145 {
  margin-left: 145px !important; }

.ml-150 {
  margin-left: 150px !important; }

.ml-155 {
  margin-left: 155px !important; }

.ml-160 {
  margin-left: 160px !important; }

.ml-165 {
  margin-left: 165px !important; }

.ml-170 {
  margin-left: 170px !important; }

.ml-175 {
  margin-left: 175px !important; }

.ml-180 {
  margin-left: 180px !important; }

.ml-185 {
  margin-left: 185px !important; }

.ml-190 {
  margin-left: 190px !important; }

.ml-195 {
  margin-left: 195px !important; }

.ml-200 {
  margin-left: 200px !important; }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-0 {
    margin-left: 0px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-5 {
    margin-left: 5px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-10 {
    margin-left: 10px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-15 {
    margin-left: 15px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-20 {
    margin-left: 20px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-25 {
    margin-left: 25px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-30 {
    margin-left: 30px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-35 {
    margin-left: 35px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-40 {
    margin-left: 40px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-45 {
    margin-left: 45px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-50 {
    margin-left: 50px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-55 {
    margin-left: 55px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-60 {
    margin-left: 60px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-65 {
    margin-left: 65px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-70 {
    margin-left: 70px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-75 {
    margin-left: 75px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-80 {
    margin-left: 80px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-85 {
    margin-left: 85px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-90 {
    margin-left: 90px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-95 {
    margin-left: 95px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-100 {
    margin-left: 100px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-105 {
    margin-left: 105px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-110 {
    margin-left: 110px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-115 {
    margin-left: 115px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-120 {
    margin-left: 120px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-125 {
    margin-left: 125px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-130 {
    margin-left: 130px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-135 {
    margin-left: 135px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-140 {
    margin-left: 140px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-145 {
    margin-left: 145px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-150 {
    margin-left: 150px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-155 {
    margin-left: 155px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-160 {
    margin-left: 160px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-165 {
    margin-left: 165px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-170 {
    margin-left: 170px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-175 {
    margin-left: 175px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-180 {
    margin-left: 180px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-185 {
    margin-left: 185px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-190 {
    margin-left: 190px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-195 {
    margin-left: 195px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-200 {
    margin-left: 200px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-0 {
    margin-left: 0px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-5 {
    margin-left: 5px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-10 {
    margin-left: 10px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-15 {
    margin-left: 15px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-20 {
    margin-left: 20px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-25 {
    margin-left: 25px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-30 {
    margin-left: 30px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-35 {
    margin-left: 35px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-40 {
    margin-left: 40px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-45 {
    margin-left: 45px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-50 {
    margin-left: 50px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-55 {
    margin-left: 55px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-60 {
    margin-left: 60px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-65 {
    margin-left: 65px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-70 {
    margin-left: 70px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-75 {
    margin-left: 75px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-80 {
    margin-left: 80px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-85 {
    margin-left: 85px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-90 {
    margin-left: 90px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-95 {
    margin-left: 95px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-100 {
    margin-left: 100px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-105 {
    margin-left: 105px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-110 {
    margin-left: 110px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-115 {
    margin-left: 115px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-120 {
    margin-left: 120px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-125 {
    margin-left: 125px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-130 {
    margin-left: 130px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-135 {
    margin-left: 135px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-140 {
    margin-left: 140px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-145 {
    margin-left: 145px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-150 {
    margin-left: 150px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-155 {
    margin-left: 155px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-160 {
    margin-left: 160px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-165 {
    margin-left: 165px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-170 {
    margin-left: 170px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-175 {
    margin-left: 175px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-180 {
    margin-left: 180px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-185 {
    margin-left: 185px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-190 {
    margin-left: 190px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-195 {
    margin-left: 195px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-200 {
    margin-left: 200px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-0 {
    margin-left: 0px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-5 {
    margin-left: 5px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-10 {
    margin-left: 10px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-15 {
    margin-left: 15px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-20 {
    margin-left: 20px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-25 {
    margin-left: 25px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-30 {
    margin-left: 30px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-35 {
    margin-left: 35px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-40 {
    margin-left: 40px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-45 {
    margin-left: 45px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-50 {
    margin-left: 50px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-55 {
    margin-left: 55px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-60 {
    margin-left: 60px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-65 {
    margin-left: 65px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-70 {
    margin-left: 70px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-75 {
    margin-left: 75px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-80 {
    margin-left: 80px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-85 {
    margin-left: 85px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-90 {
    margin-left: 90px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-95 {
    margin-left: 95px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-100 {
    margin-left: 100px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-105 {
    margin-left: 105px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-110 {
    margin-left: 110px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-115 {
    margin-left: 115px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-120 {
    margin-left: 120px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-125 {
    margin-left: 125px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-130 {
    margin-left: 130px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-135 {
    margin-left: 135px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-140 {
    margin-left: 140px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-145 {
    margin-left: 145px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-150 {
    margin-left: 150px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-155 {
    margin-left: 155px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-160 {
    margin-left: 160px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-165 {
    margin-left: 165px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-170 {
    margin-left: 170px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-175 {
    margin-left: 175px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-180 {
    margin-left: 180px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-185 {
    margin-left: 185px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-190 {
    margin-left: 190px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-195 {
    margin-left: 195px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-200 {
    margin-left: 200px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-0 {
    margin-left: 0px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-5 {
    margin-left: 5px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-10 {
    margin-left: 10px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-15 {
    margin-left: 15px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-20 {
    margin-left: 20px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-25 {
    margin-left: 25px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-30 {
    margin-left: 30px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-35 {
    margin-left: 35px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-40 {
    margin-left: 40px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-45 {
    margin-left: 45px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-50 {
    margin-left: 50px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-55 {
    margin-left: 55px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-60 {
    margin-left: 60px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-65 {
    margin-left: 65px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-70 {
    margin-left: 70px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-75 {
    margin-left: 75px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-80 {
    margin-left: 80px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-85 {
    margin-left: 85px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-90 {
    margin-left: 90px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-95 {
    margin-left: 95px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-100 {
    margin-left: 100px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-105 {
    margin-left: 105px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-110 {
    margin-left: 110px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-115 {
    margin-left: 115px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-120 {
    margin-left: 120px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-125 {
    margin-left: 125px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-130 {
    margin-left: 130px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-135 {
    margin-left: 135px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-140 {
    margin-left: 140px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-145 {
    margin-left: 145px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-150 {
    margin-left: 150px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-155 {
    margin-left: 155px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-160 {
    margin-left: 160px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-165 {
    margin-left: 165px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-170 {
    margin-left: 170px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-175 {
    margin-left: 175px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-180 {
    margin-left: 180px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-185 {
    margin-left: 185px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-190 {
    margin-left: 190px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-195 {
    margin-left: 195px !important; } }

@media only screen and (max-width: 767px) {
  .ml-xs-200 {
    margin-left: 200px !important; } }

/*-- Margin Right --*/
.mr-0 {
  margin-right: 0px !important; }

.mr-5 {
  margin-right: 5px !important; }

.mr-10 {
  margin-right: 10px !important; }

.mr-15 {
  margin-right: 15px !important; }

.mr-20 {
  margin-right: 20px !important; }

.mr-25 {
  margin-right: 25px !important; }

.mr-30 {
  margin-right: 30px !important; }

.mr-35 {
  margin-right: 35px !important; }

.mr-40 {
  margin-right: 40px !important; }

.mr-45 {
  margin-right: 45px !important; }

.mr-50 {
  margin-right: 50px !important; }

.mr-55 {
  margin-right: 55px !important; }

.mr-60 {
  margin-right: 60px !important; }

.mr-65 {
  margin-right: 65px !important; }

.mr-70 {
  margin-right: 70px !important; }

.mr-75 {
  margin-right: 75px !important; }

.mr-80 {
  margin-right: 80px !important; }

.mr-85 {
  margin-right: 85px !important; }

.mr-90 {
  margin-right: 90px !important; }

.mr-95 {
  margin-right: 95px !important; }

.mr-100 {
  margin-right: 100px !important; }

.mr-105 {
  margin-right: 105px !important; }

.mr-110 {
  margin-right: 110px !important; }

.mr-115 {
  margin-right: 115px !important; }

.mr-120 {
  margin-right: 120px !important; }

.mr-125 {
  margin-right: 125px !important; }

.mr-130 {
  margin-right: 130px !important; }

.mr-135 {
  margin-right: 135px !important; }

.mr-140 {
  margin-right: 140px !important; }

.mr-145 {
  margin-right: 145px !important; }

.mr-150 {
  margin-right: 150px !important; }

.mr-155 {
  margin-right: 155px !important; }

.mr-160 {
  margin-right: 160px !important; }

.mr-165 {
  margin-right: 165px !important; }

.mr-170 {
  margin-right: 170px !important; }

.mr-175 {
  margin-right: 175px !important; }

.mr-180 {
  margin-right: 180px !important; }

.mr-185 {
  margin-right: 185px !important; }

.mr-190 {
  margin-right: 190px !important; }

.mr-195 {
  margin-right: 195px !important; }

.mr-200 {
  margin-right: 200px !important; }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-0 {
    margin-right: 0px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-5 {
    margin-right: 5px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-10 {
    margin-right: 10px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-15 {
    margin-right: 15px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-20 {
    margin-right: 20px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-25 {
    margin-right: 25px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-30 {
    margin-right: 30px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-35 {
    margin-right: 35px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-40 {
    margin-right: 40px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-45 {
    margin-right: 45px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-50 {
    margin-right: 50px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-55 {
    margin-right: 55px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-60 {
    margin-right: 60px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-65 {
    margin-right: 65px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-70 {
    margin-right: 70px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-75 {
    margin-right: 75px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-80 {
    margin-right: 80px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-85 {
    margin-right: 85px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-90 {
    margin-right: 90px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-95 {
    margin-right: 95px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-100 {
    margin-right: 100px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-105 {
    margin-right: 105px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-110 {
    margin-right: 110px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-115 {
    margin-right: 115px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-120 {
    margin-right: 120px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-125 {
    margin-right: 125px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-130 {
    margin-right: 130px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-135 {
    margin-right: 135px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-140 {
    margin-right: 140px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-145 {
    margin-right: 145px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-150 {
    margin-right: 150px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-155 {
    margin-right: 155px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-160 {
    margin-right: 160px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-165 {
    margin-right: 165px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-170 {
    margin-right: 170px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-175 {
    margin-right: 175px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-180 {
    margin-right: 180px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-185 {
    margin-right: 185px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-190 {
    margin-right: 190px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-195 {
    margin-right: 195px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-200 {
    margin-right: 200px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-0 {
    margin-right: 0px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-5 {
    margin-right: 5px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-10 {
    margin-right: 10px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-15 {
    margin-right: 15px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-20 {
    margin-right: 20px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-25 {
    margin-right: 25px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-30 {
    margin-right: 30px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-35 {
    margin-right: 35px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-40 {
    margin-right: 40px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-45 {
    margin-right: 45px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-50 {
    margin-right: 50px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-55 {
    margin-right: 55px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-60 {
    margin-right: 60px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-65 {
    margin-right: 65px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-70 {
    margin-right: 70px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-75 {
    margin-right: 75px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-80 {
    margin-right: 80px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-85 {
    margin-right: 85px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-90 {
    margin-right: 90px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-95 {
    margin-right: 95px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-100 {
    margin-right: 100px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-105 {
    margin-right: 105px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-110 {
    margin-right: 110px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-115 {
    margin-right: 115px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-120 {
    margin-right: 120px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-125 {
    margin-right: 125px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-130 {
    margin-right: 130px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-135 {
    margin-right: 135px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-140 {
    margin-right: 140px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-145 {
    margin-right: 145px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-150 {
    margin-right: 150px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-155 {
    margin-right: 155px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-160 {
    margin-right: 160px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-165 {
    margin-right: 165px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-170 {
    margin-right: 170px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-175 {
    margin-right: 175px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-180 {
    margin-right: 180px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-185 {
    margin-right: 185px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-190 {
    margin-right: 190px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-195 {
    margin-right: 195px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-200 {
    margin-right: 200px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-0 {
    margin-right: 0px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-5 {
    margin-right: 5px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-10 {
    margin-right: 10px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-15 {
    margin-right: 15px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-20 {
    margin-right: 20px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-25 {
    margin-right: 25px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-30 {
    margin-right: 30px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-35 {
    margin-right: 35px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-40 {
    margin-right: 40px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-45 {
    margin-right: 45px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-50 {
    margin-right: 50px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-55 {
    margin-right: 55px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-60 {
    margin-right: 60px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-65 {
    margin-right: 65px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-70 {
    margin-right: 70px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-75 {
    margin-right: 75px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-80 {
    margin-right: 80px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-85 {
    margin-right: 85px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-90 {
    margin-right: 90px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-95 {
    margin-right: 95px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-100 {
    margin-right: 100px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-105 {
    margin-right: 105px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-110 {
    margin-right: 110px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-115 {
    margin-right: 115px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-120 {
    margin-right: 120px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-125 {
    margin-right: 125px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-130 {
    margin-right: 130px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-135 {
    margin-right: 135px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-140 {
    margin-right: 140px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-145 {
    margin-right: 145px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-150 {
    margin-right: 150px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-155 {
    margin-right: 155px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-160 {
    margin-right: 160px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-165 {
    margin-right: 165px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-170 {
    margin-right: 170px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-175 {
    margin-right: 175px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-180 {
    margin-right: 180px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-185 {
    margin-right: 185px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-190 {
    margin-right: 190px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-195 {
    margin-right: 195px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-200 {
    margin-right: 200px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-0 {
    margin-right: 0px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-5 {
    margin-right: 5px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-10 {
    margin-right: 10px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-15 {
    margin-right: 15px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-20 {
    margin-right: 20px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-25 {
    margin-right: 25px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-30 {
    margin-right: 30px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-35 {
    margin-right: 35px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-40 {
    margin-right: 40px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-45 {
    margin-right: 45px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-50 {
    margin-right: 50px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-55 {
    margin-right: 55px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-60 {
    margin-right: 60px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-65 {
    margin-right: 65px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-70 {
    margin-right: 70px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-75 {
    margin-right: 75px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-80 {
    margin-right: 80px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-85 {
    margin-right: 85px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-90 {
    margin-right: 90px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-95 {
    margin-right: 95px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-100 {
    margin-right: 100px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-105 {
    margin-right: 105px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-110 {
    margin-right: 110px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-115 {
    margin-right: 115px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-120 {
    margin-right: 120px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-125 {
    margin-right: 125px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-130 {
    margin-right: 130px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-135 {
    margin-right: 135px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-140 {
    margin-right: 140px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-145 {
    margin-right: 145px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-150 {
    margin-right: 150px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-155 {
    margin-right: 155px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-160 {
    margin-right: 160px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-165 {
    margin-right: 165px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-170 {
    margin-right: 170px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-175 {
    margin-right: 175px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-180 {
    margin-right: 180px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-185 {
    margin-right: 185px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-190 {
    margin-right: 190px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-195 {
    margin-right: 195px !important; } }

@media only screen and (max-width: 767px) {
  .mr-xs-200 {
    margin-right: 200px !important; } }

/*-- Padding Top --*/
.pt-0 {
  padding-top: 0px !important; }

.pt-5 {
  padding-top: 5px !important; }

.pt-10 {
  padding-top: 10px !important; }

.pt-15 {
  padding-top: 15px !important; }

.pt-20 {
  padding-top: 20px !important; }

.pt-25 {
  padding-top: 25px !important; }

.pt-30 {
  padding-top: 30px !important; }

.pt-35 {
  padding-top: 35px !important; }

.pt-40 {
  padding-top: 40px !important; }

.pt-45 {
  padding-top: 45px !important; }

.pt-50 {
  padding-top: 50px !important; }

.pt-55 {
  padding-top: 55px !important; }

.pt-60 {
  padding-top: 60px !important; }

.pt-65 {
  padding-top: 65px !important; }

.pt-70 {
  padding-top: 70px !important; }

.pt-75 {
  padding-top: 75px !important; }

.pt-80 {
  padding-top: 80px !important; }

.pt-85 {
  padding-top: 85px !important; }

.pt-90 {
  padding-top: 90px !important; }

.pt-95 {
  padding-top: 95px !important; }

.pt-100 {
  padding-top: 100px !important; }

.pt-105 {
  padding-top: 105px !important; }

.pt-110 {
  padding-top: 110px !important; }

.pt-115 {
  padding-top: 115px !important; }

.pt-120 {
  padding-top: 120px !important; }

.pt-125 {
  padding-top: 125px !important; }

.pt-130 {
  padding-top: 130px !important; }

.pt-135 {
  padding-top: 135px !important; }

.pt-140 {
  padding-top: 140px !important; }

.pt-145 {
  padding-top: 145px !important; }

.pt-150 {
  padding-top: 150px !important; }

.pt-155 {
  padding-top: 155px !important; }

.pt-160 {
  padding-top: 160px !important; }

.pt-165 {
  padding-top: 165px !important; }

.pt-170 {
  padding-top: 170px !important; }

.pt-175 {
  padding-top: 175px !important; }

.pt-180 {
  padding-top: 180px !important; }

.pt-185 {
  padding-top: 185px !important; }

.pt-190 {
  padding-top: 190px !important; }

.pt-195 {
  padding-top: 195px !important; }

.pt-200 {
  padding-top: 200px !important; }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-0 {
    padding-top: 0px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-5 {
    padding-top: 5px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-10 {
    padding-top: 10px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-15 {
    padding-top: 15px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-20 {
    padding-top: 20px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-25 {
    padding-top: 25px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-30 {
    padding-top: 30px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-35 {
    padding-top: 35px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-40 {
    padding-top: 40px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-45 {
    padding-top: 45px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-50 {
    padding-top: 50px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-55 {
    padding-top: 55px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-60 {
    padding-top: 60px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-65 {
    padding-top: 65px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-70 {
    padding-top: 70px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-75 {
    padding-top: 75px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-80 {
    padding-top: 80px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-85 {
    padding-top: 85px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-90 {
    padding-top: 90px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-95 {
    padding-top: 95px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-100 {
    padding-top: 100px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-105 {
    padding-top: 105px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-110 {
    padding-top: 110px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-115 {
    padding-top: 115px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-120 {
    padding-top: 120px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-125 {
    padding-top: 125px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-130 {
    padding-top: 130px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-135 {
    padding-top: 135px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-140 {
    padding-top: 140px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-145 {
    padding-top: 145px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-150 {
    padding-top: 150px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-155 {
    padding-top: 155px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-160 {
    padding-top: 160px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-165 {
    padding-top: 165px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-170 {
    padding-top: 170px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-175 {
    padding-top: 175px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-180 {
    padding-top: 180px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-185 {
    padding-top: 185px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-190 {
    padding-top: 190px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-195 {
    padding-top: 195px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-200 {
    padding-top: 200px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-0 {
    padding-top: 0px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-5 {
    padding-top: 5px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-10 {
    padding-top: 10px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-15 {
    padding-top: 15px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-20 {
    padding-top: 20px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-25 {
    padding-top: 25px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-30 {
    padding-top: 30px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-35 {
    padding-top: 35px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-40 {
    padding-top: 40px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-45 {
    padding-top: 45px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-50 {
    padding-top: 50px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-55 {
    padding-top: 55px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-60 {
    padding-top: 60px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-65 {
    padding-top: 65px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-70 {
    padding-top: 70px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-75 {
    padding-top: 75px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-80 {
    padding-top: 80px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-85 {
    padding-top: 85px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-90 {
    padding-top: 90px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-95 {
    padding-top: 95px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-100 {
    padding-top: 100px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-105 {
    padding-top: 105px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-110 {
    padding-top: 110px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-115 {
    padding-top: 115px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-120 {
    padding-top: 120px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-125 {
    padding-top: 125px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-130 {
    padding-top: 130px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-135 {
    padding-top: 135px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-140 {
    padding-top: 140px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-145 {
    padding-top: 145px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-150 {
    padding-top: 150px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-155 {
    padding-top: 155px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-160 {
    padding-top: 160px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-165 {
    padding-top: 165px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-170 {
    padding-top: 170px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-175 {
    padding-top: 175px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-180 {
    padding-top: 180px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-185 {
    padding-top: 185px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-190 {
    padding-top: 190px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-195 {
    padding-top: 195px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-200 {
    padding-top: 200px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-0 {
    padding-top: 0px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-5 {
    padding-top: 5px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-10 {
    padding-top: 10px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-15 {
    padding-top: 15px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-20 {
    padding-top: 20px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-25 {
    padding-top: 25px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-30 {
    padding-top: 30px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-35 {
    padding-top: 35px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-40 {
    padding-top: 40px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-45 {
    padding-top: 45px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-50 {
    padding-top: 50px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-55 {
    padding-top: 55px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-60 {
    padding-top: 60px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-65 {
    padding-top: 65px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-70 {
    padding-top: 70px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-75 {
    padding-top: 75px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-80 {
    padding-top: 80px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-85 {
    padding-top: 85px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-90 {
    padding-top: 90px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-95 {
    padding-top: 95px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-100 {
    padding-top: 100px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-105 {
    padding-top: 105px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-110 {
    padding-top: 110px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-115 {
    padding-top: 115px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-120 {
    padding-top: 120px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-125 {
    padding-top: 125px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-130 {
    padding-top: 130px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-135 {
    padding-top: 135px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-140 {
    padding-top: 140px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-145 {
    padding-top: 145px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-150 {
    padding-top: 150px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-155 {
    padding-top: 155px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-160 {
    padding-top: 160px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-165 {
    padding-top: 165px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-170 {
    padding-top: 170px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-175 {
    padding-top: 175px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-180 {
    padding-top: 180px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-185 {
    padding-top: 185px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-190 {
    padding-top: 190px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-195 {
    padding-top: 195px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-200 {
    padding-top: 200px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-0 {
    padding-top: 0px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-5 {
    padding-top: 5px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-10 {
    padding-top: 10px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-15 {
    padding-top: 15px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-20 {
    padding-top: 20px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-25 {
    padding-top: 25px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-30 {
    padding-top: 30px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-35 {
    padding-top: 35px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-40 {
    padding-top: 40px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-45 {
    padding-top: 45px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-50 {
    padding-top: 50px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-55 {
    padding-top: 55px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-60 {
    padding-top: 60px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-65 {
    padding-top: 65px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-70 {
    padding-top: 70px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-75 {
    padding-top: 75px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-80 {
    padding-top: 80px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-85 {
    padding-top: 85px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-90 {
    padding-top: 90px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-95 {
    padding-top: 95px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-100 {
    padding-top: 100px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-105 {
    padding-top: 105px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-110 {
    padding-top: 110px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-115 {
    padding-top: 115px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-120 {
    padding-top: 120px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-125 {
    padding-top: 125px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-130 {
    padding-top: 130px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-135 {
    padding-top: 135px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-140 {
    padding-top: 140px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-145 {
    padding-top: 145px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-150 {
    padding-top: 150px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-155 {
    padding-top: 155px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-160 {
    padding-top: 160px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-165 {
    padding-top: 165px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-170 {
    padding-top: 170px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-175 {
    padding-top: 175px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-180 {
    padding-top: 180px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-185 {
    padding-top: 185px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-190 {
    padding-top: 190px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-195 {
    padding-top: 195px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-200 {
    padding-top: 200px !important; } }

/*-- Padding Bottom --*/
.pb-0 {
  padding-bottom: 0px !important; }

.pb-5 {
  padding-bottom: 5px !important; }

.pb-10 {
  padding-bottom: 10px !important; }

.pb-15 {
  padding-bottom: 15px !important; }

.pb-20 {
  padding-bottom: 20px !important; }

.pb-25 {
  padding-bottom: 25px !important; }

.pb-30 {
  padding-bottom: 30px !important; }

.pb-35 {
  padding-bottom: 35px !important; }

.pb-40 {
  padding-bottom: 40px !important; }

.pb-45 {
  padding-bottom: 45px !important; }

.pb-50 {
  padding-bottom: 50px !important; }

.pb-55 {
  padding-bottom: 55px !important; }

.pb-60 {
  padding-bottom: 60px !important; }

.pb-65 {
  padding-bottom: 65px !important; }

.pb-70 {
  padding-bottom: 70px !important; }

.pb-75 {
  padding-bottom: 75px !important; }

.pb-80 {
  padding-bottom: 80px !important; }

.pb-85 {
  padding-bottom: 85px !important; }

.pb-90 {
  padding-bottom: 90px !important; }

.pb-95 {
  padding-bottom: 95px !important; }

.pb-100 {
  padding-bottom: 100px !important; }

.pb-105 {
  padding-bottom: 105px !important; }

.pb-110 {
  padding-bottom: 110px !important; }

.pb-115 {
  padding-bottom: 115px !important; }

.pb-120 {
  padding-bottom: 120px !important; }

.pb-125 {
  padding-bottom: 125px !important; }

.pb-130 {
  padding-bottom: 130px !important; }

.pb-135 {
  padding-bottom: 135px !important; }

.pb-140 {
  padding-bottom: 140px !important; }

.pb-145 {
  padding-bottom: 145px !important; }

.pb-150 {
  padding-bottom: 150px !important; }

.pb-155 {
  padding-bottom: 155px !important; }

.pb-160 {
  padding-bottom: 160px !important; }

.pb-165 {
  padding-bottom: 165px !important; }

.pb-170 {
  padding-bottom: 170px !important; }

.pb-175 {
  padding-bottom: 175px !important; }

.pb-180 {
  padding-bottom: 180px !important; }

.pb-185 {
  padding-bottom: 185px !important; }

.pb-190 {
  padding-bottom: 190px !important; }

.pb-195 {
  padding-bottom: 195px !important; }

.pb-200 {
  padding-bottom: 200px !important; }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-0 {
    padding-bottom: 0px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-5 {
    padding-bottom: 5px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-10 {
    padding-bottom: 10px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-15 {
    padding-bottom: 15px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-20 {
    padding-bottom: 20px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-25 {
    padding-bottom: 25px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-30 {
    padding-bottom: 30px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-35 {
    padding-bottom: 35px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-40 {
    padding-bottom: 40px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-45 {
    padding-bottom: 45px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-50 {
    padding-bottom: 50px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-55 {
    padding-bottom: 55px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-60 {
    padding-bottom: 60px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-65 {
    padding-bottom: 65px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-70 {
    padding-bottom: 70px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-75 {
    padding-bottom: 75px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-80 {
    padding-bottom: 80px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-85 {
    padding-bottom: 85px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-90 {
    padding-bottom: 90px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-95 {
    padding-bottom: 95px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-100 {
    padding-bottom: 100px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-105 {
    padding-bottom: 105px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-110 {
    padding-bottom: 110px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-115 {
    padding-bottom: 115px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-120 {
    padding-bottom: 120px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-125 {
    padding-bottom: 125px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-130 {
    padding-bottom: 130px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-135 {
    padding-bottom: 135px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-140 {
    padding-bottom: 140px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-145 {
    padding-bottom: 145px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-150 {
    padding-bottom: 150px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-155 {
    padding-bottom: 155px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-160 {
    padding-bottom: 160px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-165 {
    padding-bottom: 165px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-170 {
    padding-bottom: 170px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-175 {
    padding-bottom: 175px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-180 {
    padding-bottom: 180px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-185 {
    padding-bottom: 185px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-190 {
    padding-bottom: 190px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-195 {
    padding-bottom: 195px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-200 {
    padding-bottom: 200px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-0 {
    padding-bottom: 0px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-5 {
    padding-bottom: 5px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-10 {
    padding-bottom: 10px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-15 {
    padding-bottom: 15px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-20 {
    padding-bottom: 20px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-25 {
    padding-bottom: 25px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-30 {
    padding-bottom: 30px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-35 {
    padding-bottom: 35px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-40 {
    padding-bottom: 40px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-45 {
    padding-bottom: 45px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-50 {
    padding-bottom: 50px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-55 {
    padding-bottom: 55px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-60 {
    padding-bottom: 60px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-65 {
    padding-bottom: 65px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-70 {
    padding-bottom: 70px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-75 {
    padding-bottom: 75px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-80 {
    padding-bottom: 80px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-85 {
    padding-bottom: 85px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-90 {
    padding-bottom: 90px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-95 {
    padding-bottom: 95px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-100 {
    padding-bottom: 100px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-105 {
    padding-bottom: 105px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-110 {
    padding-bottom: 110px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-115 {
    padding-bottom: 115px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-120 {
    padding-bottom: 120px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-125 {
    padding-bottom: 125px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-130 {
    padding-bottom: 130px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-135 {
    padding-bottom: 135px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-140 {
    padding-bottom: 140px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-145 {
    padding-bottom: 145px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-150 {
    padding-bottom: 150px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-155 {
    padding-bottom: 155px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-160 {
    padding-bottom: 160px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-165 {
    padding-bottom: 165px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-170 {
    padding-bottom: 170px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-175 {
    padding-bottom: 175px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-180 {
    padding-bottom: 180px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-185 {
    padding-bottom: 185px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-190 {
    padding-bottom: 190px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-195 {
    padding-bottom: 195px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-200 {
    padding-bottom: 200px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-0 {
    padding-bottom: 0px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-5 {
    padding-bottom: 5px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-10 {
    padding-bottom: 10px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-15 {
    padding-bottom: 15px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-20 {
    padding-bottom: 20px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-25 {
    padding-bottom: 25px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-30 {
    padding-bottom: 30px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-35 {
    padding-bottom: 35px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-40 {
    padding-bottom: 40px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-45 {
    padding-bottom: 45px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-50 {
    padding-bottom: 50px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-55 {
    padding-bottom: 55px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-60 {
    padding-bottom: 60px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-65 {
    padding-bottom: 65px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-70 {
    padding-bottom: 70px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-75 {
    padding-bottom: 75px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-80 {
    padding-bottom: 80px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-85 {
    padding-bottom: 85px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-90 {
    padding-bottom: 90px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-95 {
    padding-bottom: 95px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-100 {
    padding-bottom: 100px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-105 {
    padding-bottom: 105px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-110 {
    padding-bottom: 110px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-115 {
    padding-bottom: 115px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-120 {
    padding-bottom: 120px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-125 {
    padding-bottom: 125px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-130 {
    padding-bottom: 130px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-135 {
    padding-bottom: 135px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-140 {
    padding-bottom: 140px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-145 {
    padding-bottom: 145px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-150 {
    padding-bottom: 150px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-155 {
    padding-bottom: 155px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-160 {
    padding-bottom: 160px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-165 {
    padding-bottom: 165px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-170 {
    padding-bottom: 170px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-175 {
    padding-bottom: 175px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-180 {
    padding-bottom: 180px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-185 {
    padding-bottom: 185px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-190 {
    padding-bottom: 190px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-195 {
    padding-bottom: 195px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-200 {
    padding-bottom: 200px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-0 {
    padding-bottom: 0px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-5 {
    padding-bottom: 5px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-10 {
    padding-bottom: 10px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-15 {
    padding-bottom: 15px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-20 {
    padding-bottom: 20px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-25 {
    padding-bottom: 25px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-30 {
    padding-bottom: 30px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-35 {
    padding-bottom: 35px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-40 {
    padding-bottom: 40px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-45 {
    padding-bottom: 45px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-50 {
    padding-bottom: 50px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-55 {
    padding-bottom: 55px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-60 {
    padding-bottom: 60px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-65 {
    padding-bottom: 65px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-70 {
    padding-bottom: 70px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-75 {
    padding-bottom: 75px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-80 {
    padding-bottom: 80px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-85 {
    padding-bottom: 85px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-90 {
    padding-bottom: 90px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-95 {
    padding-bottom: 95px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-100 {
    padding-bottom: 100px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-105 {
    padding-bottom: 105px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-110 {
    padding-bottom: 110px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-115 {
    padding-bottom: 115px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-120 {
    padding-bottom: 120px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-125 {
    padding-bottom: 125px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-130 {
    padding-bottom: 130px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-135 {
    padding-bottom: 135px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-140 {
    padding-bottom: 140px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-145 {
    padding-bottom: 145px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-150 {
    padding-bottom: 150px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-155 {
    padding-bottom: 155px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-160 {
    padding-bottom: 160px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-165 {
    padding-bottom: 165px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-170 {
    padding-bottom: 170px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-175 {
    padding-bottom: 175px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-180 {
    padding-bottom: 180px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-185 {
    padding-bottom: 185px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-190 {
    padding-bottom: 190px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-195 {
    padding-bottom: 195px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-200 {
    padding-bottom: 200px !important; } }

/*-- Padding Left --*/
.pl-0 {
  padding-left: 0px !important; }

.pl-5 {
  padding-left: 5px !important; }

.pl-10 {
  padding-left: 10px !important; }

.pl-15 {
  padding-left: 15px !important; }

.pl-20 {
  padding-left: 20px !important; }

.pl-25 {
  padding-left: 25px !important; }

.pl-30 {
  padding-left: 30px !important; }

.pl-35 {
  padding-left: 35px !important; }

.pl-40 {
  padding-left: 40px !important; }

.pl-45 {
  padding-left: 45px !important; }

.pl-50 {
  padding-left: 50px !important; }

.pl-55 {
  padding-left: 55px !important; }

.pl-60 {
  padding-left: 60px !important; }

.pl-65 {
  padding-left: 65px !important; }

.pl-70 {
  padding-left: 70px !important; }

.pl-75 {
  padding-left: 75px !important; }

.pl-80 {
  padding-left: 80px !important; }

.pl-85 {
  padding-left: 85px !important; }

.pl-90 {
  padding-left: 90px !important; }

.pl-95 {
  padding-left: 95px !important; }

.pl-100 {
  padding-left: 100px !important; }

.pl-105 {
  padding-left: 105px !important; }

.pl-110 {
  padding-left: 110px !important; }

.pl-115 {
  padding-left: 115px !important; }

.pl-120 {
  padding-left: 120px !important; }

.pl-125 {
  padding-left: 125px !important; }

.pl-130 {
  padding-left: 130px !important; }

.pl-135 {
  padding-left: 135px !important; }

.pl-140 {
  padding-left: 140px !important; }

.pl-145 {
  padding-left: 145px !important; }

.pl-150 {
  padding-left: 150px !important; }

.pl-155 {
  padding-left: 155px !important; }

.pl-160 {
  padding-left: 160px !important; }

.pl-165 {
  padding-left: 165px !important; }

.pl-170 {
  padding-left: 170px !important; }

.pl-175 {
  padding-left: 175px !important; }

.pl-180 {
  padding-left: 180px !important; }

.pl-185 {
  padding-left: 185px !important; }

.pl-190 {
  padding-left: 190px !important; }

.pl-195 {
  padding-left: 195px !important; }

.pl-200 {
  padding-left: 200px !important; }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-0 {
    padding-left: 0px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-5 {
    padding-left: 5px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-10 {
    padding-left: 10px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-15 {
    padding-left: 15px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-20 {
    padding-left: 20px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-25 {
    padding-left: 25px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-30 {
    padding-left: 30px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-35 {
    padding-left: 35px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-40 {
    padding-left: 40px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-45 {
    padding-left: 45px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-50 {
    padding-left: 50px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-55 {
    padding-left: 55px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-60 {
    padding-left: 60px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-65 {
    padding-left: 65px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-70 {
    padding-left: 70px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-75 {
    padding-left: 75px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-80 {
    padding-left: 80px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-85 {
    padding-left: 85px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-90 {
    padding-left: 90px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-95 {
    padding-left: 95px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-100 {
    padding-left: 100px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-105 {
    padding-left: 105px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-110 {
    padding-left: 110px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-115 {
    padding-left: 115px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-120 {
    padding-left: 120px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-125 {
    padding-left: 125px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-130 {
    padding-left: 130px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-135 {
    padding-left: 135px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-140 {
    padding-left: 140px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-145 {
    padding-left: 145px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-150 {
    padding-left: 150px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-155 {
    padding-left: 155px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-160 {
    padding-left: 160px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-165 {
    padding-left: 165px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-170 {
    padding-left: 170px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-175 {
    padding-left: 175px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-180 {
    padding-left: 180px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-185 {
    padding-left: 185px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-190 {
    padding-left: 190px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-195 {
    padding-left: 195px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-200 {
    padding-left: 200px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-0 {
    padding-left: 0px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-5 {
    padding-left: 5px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-10 {
    padding-left: 10px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-15 {
    padding-left: 15px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-20 {
    padding-left: 20px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-25 {
    padding-left: 25px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-30 {
    padding-left: 30px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-35 {
    padding-left: 35px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-40 {
    padding-left: 40px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-45 {
    padding-left: 45px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-50 {
    padding-left: 50px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-55 {
    padding-left: 55px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-60 {
    padding-left: 60px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-65 {
    padding-left: 65px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-70 {
    padding-left: 70px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-75 {
    padding-left: 75px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-80 {
    padding-left: 80px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-85 {
    padding-left: 85px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-90 {
    padding-left: 90px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-95 {
    padding-left: 95px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-100 {
    padding-left: 100px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-105 {
    padding-left: 105px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-110 {
    padding-left: 110px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-115 {
    padding-left: 115px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-120 {
    padding-left: 120px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-125 {
    padding-left: 125px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-130 {
    padding-left: 130px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-135 {
    padding-left: 135px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-140 {
    padding-left: 140px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-145 {
    padding-left: 145px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-150 {
    padding-left: 150px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-155 {
    padding-left: 155px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-160 {
    padding-left: 160px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-165 {
    padding-left: 165px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-170 {
    padding-left: 170px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-175 {
    padding-left: 175px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-180 {
    padding-left: 180px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-185 {
    padding-left: 185px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-190 {
    padding-left: 190px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-195 {
    padding-left: 195px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-200 {
    padding-left: 200px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-0 {
    padding-left: 0px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-5 {
    padding-left: 5px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-10 {
    padding-left: 10px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-15 {
    padding-left: 15px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-20 {
    padding-left: 20px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-25 {
    padding-left: 25px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-30 {
    padding-left: 30px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-35 {
    padding-left: 35px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-40 {
    padding-left: 40px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-45 {
    padding-left: 45px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-50 {
    padding-left: 50px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-55 {
    padding-left: 55px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-60 {
    padding-left: 60px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-65 {
    padding-left: 65px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-70 {
    padding-left: 70px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-75 {
    padding-left: 75px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-80 {
    padding-left: 80px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-85 {
    padding-left: 85px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-90 {
    padding-left: 90px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-95 {
    padding-left: 95px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-100 {
    padding-left: 100px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-105 {
    padding-left: 105px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-110 {
    padding-left: 110px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-115 {
    padding-left: 115px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-120 {
    padding-left: 120px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-125 {
    padding-left: 125px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-130 {
    padding-left: 130px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-135 {
    padding-left: 135px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-140 {
    padding-left: 140px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-145 {
    padding-left: 145px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-150 {
    padding-left: 150px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-155 {
    padding-left: 155px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-160 {
    padding-left: 160px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-165 {
    padding-left: 165px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-170 {
    padding-left: 170px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-175 {
    padding-left: 175px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-180 {
    padding-left: 180px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-185 {
    padding-left: 185px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-190 {
    padding-left: 190px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-195 {
    padding-left: 195px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-200 {
    padding-left: 200px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-0 {
    padding-left: 0px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-5 {
    padding-left: 5px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-10 {
    padding-left: 10px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-15 {
    padding-left: 15px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-20 {
    padding-left: 20px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-25 {
    padding-left: 25px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-30 {
    padding-left: 30px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-35 {
    padding-left: 35px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-40 {
    padding-left: 40px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-45 {
    padding-left: 45px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-50 {
    padding-left: 50px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-55 {
    padding-left: 55px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-60 {
    padding-left: 60px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-65 {
    padding-left: 65px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-70 {
    padding-left: 70px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-75 {
    padding-left: 75px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-80 {
    padding-left: 80px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-85 {
    padding-left: 85px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-90 {
    padding-left: 90px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-95 {
    padding-left: 95px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-100 {
    padding-left: 100px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-105 {
    padding-left: 105px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-110 {
    padding-left: 110px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-115 {
    padding-left: 115px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-120 {
    padding-left: 120px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-125 {
    padding-left: 125px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-130 {
    padding-left: 130px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-135 {
    padding-left: 135px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-140 {
    padding-left: 140px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-145 {
    padding-left: 145px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-150 {
    padding-left: 150px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-155 {
    padding-left: 155px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-160 {
    padding-left: 160px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-165 {
    padding-left: 165px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-170 {
    padding-left: 170px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-175 {
    padding-left: 175px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-180 {
    padding-left: 180px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-185 {
    padding-left: 185px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-190 {
    padding-left: 190px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-195 {
    padding-left: 195px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-200 {
    padding-left: 200px !important; } }

/*-- Padding Right --*/
.pr-0 {
  padding-right: 0px !important; }

.pr-5 {
  padding-right: 5px !important; }

.pr-10 {
  padding-right: 10px !important; }

.pr-15 {
  padding-right: 15px !important; }

.pr-20 {
  padding-right: 20px !important; }

.pr-25 {
  padding-right: 25px !important; }

.pr-30 {
  padding-right: 30px !important; }

.pr-35 {
  padding-right: 35px !important; }

.pr-40 {
  padding-right: 40px !important; }

.pr-45 {
  padding-right: 45px !important; }

.pr-50 {
  padding-right: 50px !important; }

.pr-55 {
  padding-right: 55px !important; }

.pr-60 {
  padding-right: 60px !important; }

.pr-65 {
  padding-right: 65px !important; }

.pr-70 {
  padding-right: 70px !important; }

.pr-75 {
  padding-right: 75px !important; }

.pr-80 {
  padding-right: 80px !important; }

.pr-85 {
  padding-right: 85px !important; }

.pr-90 {
  padding-right: 90px !important; }

.pr-95 {
  padding-right: 95px !important; }

.pr-100 {
  padding-right: 100px !important; }

.pr-105 {
  padding-right: 105px !important; }

.pr-110 {
  padding-right: 110px !important; }

.pr-115 {
  padding-right: 115px !important; }

.pr-120 {
  padding-right: 120px !important; }

.pr-125 {
  padding-right: 125px !important; }

.pr-130 {
  padding-right: 130px !important; }

.pr-135 {
  padding-right: 135px !important; }

.pr-140 {
  padding-right: 140px !important; }

.pr-145 {
  padding-right: 145px !important; }

.pr-150 {
  padding-right: 150px !important; }

.pr-155 {
  padding-right: 155px !important; }

.pr-160 {
  padding-right: 160px !important; }

.pr-165 {
  padding-right: 165px !important; }

.pr-170 {
  padding-right: 170px !important; }

.pr-175 {
  padding-right: 175px !important; }

.pr-180 {
  padding-right: 180px !important; }

.pr-185 {
  padding-right: 185px !important; }

.pr-190 {
  padding-right: 190px !important; }

.pr-195 {
  padding-right: 195px !important; }

.pr-200 {
  padding-right: 200px !important; }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-0 {
    padding-right: 0px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-5 {
    padding-right: 5px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-10 {
    padding-right: 10px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-15 {
    padding-right: 15px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-20 {
    padding-right: 20px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-25 {
    padding-right: 25px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-30 {
    padding-right: 30px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-35 {
    padding-right: 35px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-40 {
    padding-right: 40px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-45 {
    padding-right: 45px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-50 {
    padding-right: 50px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-55 {
    padding-right: 55px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-60 {
    padding-right: 60px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-65 {
    padding-right: 65px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-70 {
    padding-right: 70px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-75 {
    padding-right: 75px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-80 {
    padding-right: 80px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-85 {
    padding-right: 85px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-90 {
    padding-right: 90px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-95 {
    padding-right: 95px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-100 {
    padding-right: 100px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-105 {
    padding-right: 105px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-110 {
    padding-right: 110px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-115 {
    padding-right: 115px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-120 {
    padding-right: 120px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-125 {
    padding-right: 125px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-130 {
    padding-right: 130px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-135 {
    padding-right: 135px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-140 {
    padding-right: 140px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-145 {
    padding-right: 145px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-150 {
    padding-right: 150px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-155 {
    padding-right: 155px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-160 {
    padding-right: 160px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-165 {
    padding-right: 165px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-170 {
    padding-right: 170px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-175 {
    padding-right: 175px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-180 {
    padding-right: 180px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-185 {
    padding-right: 185px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-190 {
    padding-right: 190px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-195 {
    padding-right: 195px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-200 {
    padding-right: 200px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-0 {
    padding-right: 0px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-5 {
    padding-right: 5px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-10 {
    padding-right: 10px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-15 {
    padding-right: 15px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-20 {
    padding-right: 20px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-25 {
    padding-right: 25px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-30 {
    padding-right: 30px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-35 {
    padding-right: 35px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-40 {
    padding-right: 40px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-45 {
    padding-right: 45px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-50 {
    padding-right: 50px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-55 {
    padding-right: 55px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-60 {
    padding-right: 60px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-65 {
    padding-right: 65px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-70 {
    padding-right: 70px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-75 {
    padding-right: 75px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-80 {
    padding-right: 80px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-85 {
    padding-right: 85px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-90 {
    padding-right: 90px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-95 {
    padding-right: 95px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-100 {
    padding-right: 100px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-105 {
    padding-right: 105px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-110 {
    padding-right: 110px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-115 {
    padding-right: 115px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-120 {
    padding-right: 120px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-125 {
    padding-right: 125px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-130 {
    padding-right: 130px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-135 {
    padding-right: 135px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-140 {
    padding-right: 140px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-145 {
    padding-right: 145px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-150 {
    padding-right: 150px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-155 {
    padding-right: 155px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-160 {
    padding-right: 160px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-165 {
    padding-right: 165px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-170 {
    padding-right: 170px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-175 {
    padding-right: 175px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-180 {
    padding-right: 180px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-185 {
    padding-right: 185px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-190 {
    padding-right: 190px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-195 {
    padding-right: 195px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-200 {
    padding-right: 200px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-0 {
    padding-right: 0px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-5 {
    padding-right: 5px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-10 {
    padding-right: 10px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-15 {
    padding-right: 15px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-20 {
    padding-right: 20px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-25 {
    padding-right: 25px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-30 {
    padding-right: 30px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-35 {
    padding-right: 35px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-40 {
    padding-right: 40px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-45 {
    padding-right: 45px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-50 {
    padding-right: 50px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-55 {
    padding-right: 55px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-60 {
    padding-right: 60px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-65 {
    padding-right: 65px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-70 {
    padding-right: 70px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-75 {
    padding-right: 75px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-80 {
    padding-right: 80px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-85 {
    padding-right: 85px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-90 {
    padding-right: 90px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-95 {
    padding-right: 95px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-100 {
    padding-right: 100px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-105 {
    padding-right: 105px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-110 {
    padding-right: 110px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-115 {
    padding-right: 115px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-120 {
    padding-right: 120px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-125 {
    padding-right: 125px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-130 {
    padding-right: 130px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-135 {
    padding-right: 135px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-140 {
    padding-right: 140px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-145 {
    padding-right: 145px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-150 {
    padding-right: 150px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-155 {
    padding-right: 155px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-160 {
    padding-right: 160px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-165 {
    padding-right: 165px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-170 {
    padding-right: 170px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-175 {
    padding-right: 175px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-180 {
    padding-right: 180px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-185 {
    padding-right: 185px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-190 {
    padding-right: 190px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-195 {
    padding-right: 195px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-200 {
    padding-right: 200px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-0 {
    padding-right: 0px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-5 {
    padding-right: 5px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-10 {
    padding-right: 10px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-15 {
    padding-right: 15px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-20 {
    padding-right: 20px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-25 {
    padding-right: 25px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-30 {
    padding-right: 30px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-35 {
    padding-right: 35px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-40 {
    padding-right: 40px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-45 {
    padding-right: 45px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-50 {
    padding-right: 50px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-55 {
    padding-right: 55px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-60 {
    padding-right: 60px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-65 {
    padding-right: 65px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-70 {
    padding-right: 70px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-75 {
    padding-right: 75px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-80 {
    padding-right: 80px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-85 {
    padding-right: 85px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-90 {
    padding-right: 90px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-95 {
    padding-right: 95px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-100 {
    padding-right: 100px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-105 {
    padding-right: 105px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-110 {
    padding-right: 110px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-115 {
    padding-right: 115px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-120 {
    padding-right: 120px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-125 {
    padding-right: 125px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-130 {
    padding-right: 130px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-135 {
    padding-right: 135px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-140 {
    padding-right: 140px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-145 {
    padding-right: 145px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-150 {
    padding-right: 150px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-155 {
    padding-right: 155px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-160 {
    padding-right: 160px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-165 {
    padding-right: 165px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-170 {
    padding-right: 170px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-175 {
    padding-right: 175px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-180 {
    padding-right: 180px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-185 {
    padding-right: 185px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-190 {
    padding-right: 190px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-195 {
    padding-right: 195px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-200 {
    padding-right: 200px !important; } }
