
// Brand Colors
$color-brand-01:                  #0168fa;
$color-brand-02:                  #042893;
$color-brand-03:                  #1ce1ac;

// Inverse Colors
$color-inverse-01:                #001737;
$color-inverse-02:                #1b2e4b;;

// Text Colors
$color-text-01:                   $color-inverse-01;
$color-text-02:                   $color-inverse-02;
$color-text-03:                   #8392a5;
$color-text-04:                   #c0ccda;

// UI Colors
$color-ui-01:                     #f5f6fa;
$color-ui-02:                     #e5e9f2;
$color-ui-03:                     #c0ccda;
$color-ui-04:                     #8392a5;

// Height
$height-base:                     38px;
$height-header:                   60px;
$height-header-mobile:            55px;
$height-footer:                   46px;

// Width
$width-sidebar:                   240px;

// Marker
$marker-icon-size:                16px;

// Fonts
$font-family-roboto:              "Roboto", sans-serif;
$font-family-interui:             "Inter UI", sans-serif;
$font-family-lato:                "Lato", sans-serif;
$font-family-rubik:               "Rubik", sans-serif;
$font-family-system:              -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;

$font-weight-medium:              500;
$font-weight-semibold:            600;
$font-weight-bold:                700;



$gray-50:                         #fafbfc;
$gray-100:                        #f4f5f8;
$gray-200:                        #e3e7ed;
$gray-300:                        #cdd4e0;
$gray-400:                        #b4bdce;
$gray-500:                        #97a3b9;
$gray-600:                        #7987a1;
$gray-700:                        #596882;
$gray-800:                        #3b4863;
$gray-900:                        #1c273c;

$blue:                            $color-brand-01;
$green:                           #10b759;
$teal:                            #00cccc;
$cyan:                            #00b8d4;
$pink:                            #f10075;
$indigo:                          #5b47fb;

// Body
$body-color:                      $color-text-01;

// Border
$border-color:                    rgba(darken($color-ui-02, 50%), .16);

// Buttons + Forms
$btn-focus-box-shadow:            none;
$btn-active-box-shadow:           none;

$input-btn-padding-y:             .46875rem;
$input-btn-padding-y-sm:          .391rem;
$input-btn-padding-y-lg:          .6rem;
$input-btn-padding-x:             .625rem;

$input-btn-font-size:             .875rem;
$input-btn-font-size-sm:          .8125rem;

// Buttons
$btn-padding-x:                   .9375rem;

// Card
$card-border-color:               $border-color;

// Close
$close-color:                     $color-text-02;

// Fonts
$font-size-base:                  .875rem;
$font-family-base:                'IBM Plex Sans', sans-serif;

$headings-font-weight:            $font-weight-medium;
$headings-color:                  $color-text-01;
$headings-line-height:            1.25;
$headings-font-weight:            $font-weight-medium;

// Forms
$input-border-color:              $color-ui-03;
$input-group-addon-bg:            $color-ui-01;

// Grid
$grid-gutter-width:               30px;


// Links
$link-color:                      $color-brand-01;
$link-hover-decoration:           none;

// Popover
$popover-border-color:            $border-color;
