/*-----my style-----*/

.dashboard-sidebar > .dashboard-menu > .nav > .navSideBtn {
    display: flex;
    width: 100%;
    margin: 6px;
    justify-content: flex-end;
    cursor: pointer;
}

.MuiIconButton-label {
    color: #c355a0 !important;
}

.MuiFormControlLabel-label {
    color: #c355a0 !important;
}

.gradiant-css {
    background: rgb(197, 84, 174);
    background: -moz-linear-gradient(top, rgb(197, 84, 174) 1%, rgb(30, 122, 162) 99%);
    background: -webkit-linear-gradient(top, rgb(197, 84, 174) 1%, rgb(30, 122, 162) 99%);
    background: linear-gradient(to bottom, rgb(197, 84, 174) 1%, rgb(30, 122, 162) 99%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcbe1b', endColorstr='#f85648', GradientType=0);
}

table thead {
    background: rgb(197, 84, 174);
    background: -moz-linear-gradient(top, rgb(197, 84, 174) 1%, rgb(30, 122, 162) 99%);
    background: -webkit-linear-gradient(top, rgb(197, 84, 174) 1%, rgb(30, 122, 162) 99%);
    background: linear-gradient(to bottom, rgb(197, 84, 174) 1%, rgb(30, 122, 162) 99%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcbe1b', endColorstr='#f85648', GradientType=0);
}

table thead > tr > th {
    color: white !important;
    white-space: nowrap;
}


@media only screen and (max-width: 1366px) {
    .job-search-wrap {
        margin-top: 0 !important;
    }
}

.card-body > .row.p-u {
    margin-top: 5px;
}

/*.card-body > .row{*/
/*    margin-top: 50px;*/
/*}*/

.profile-applications-heading > .row > .desc {
    color: #c255a0;
    font-weight: bold;
    margin-left: 4px;
}

.applications-heading > .set-drop {
    display: flex;
    width: 300px;
    justify-content: center;
    padding: 12px 0;
}

.coming-soon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-weight: bolder;
}

.card-header {
    padding: 0 1.25rem;
}

.tm-box > .exp-edit {
    display: flex;
    top: 0;
    right: 0;
    padding: 5px 9px;
    position: absolute;
}

.btn-primary {
    background-color: #c355a0;
    border-color: #c355a0;
}

.btn-primary:hover {
    background-color: #9d4d81;
    border-color: #9d4d81;
}

.text-primary {
    color: #c355a0 !important;
}

a.text-primary:focus, a.text-primary:hover {
    color: #9d4d81 !important;
}

.row .justify-content-end > .a {
    text-decoration: none;
    display: inline-block;
    padding: 2px 12px;
    cursor: pointer;
}

.row .justify-content-end > .a:hover {
    background-color: #dd61b3;
    color: black;
}

.row .justify-content-end > .previous {
    background-color: #f1f1f1;
    color: black;
    margin: 5px;
}

.row .justify-content-end > .next {
    background-color: #f1f1f1;
    color: white;
    margin: 5px;
}

.row .justify-content-end > .previous.active {
    background-color: #c355a0;

}

.row .justify-content-end > .next.active {
    background-color: #c355a0;
}

.row .justify-content-end > .round {
    border-radius: 50%;
}

.job-pnd-hld {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.job-pnd-hld > .pnd-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 60px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.job-pnd-hld > .pnd-row > h3 {
    margin: 0;
    font-size: 16px;
}

.job-pnd-hld > .pnd-row > .ic {
    margin: 0 8px;
}

.job-pnd-hld > .pnd-row > .title {
    display: flex;
    font-size: 16px;
    margin-right: 8px;
}

.job-pnd-hld > .pnd-row > .noe {
    display: flex;
    margin-left: 8px;
    font-weight: bolder;
}

/*------switch css--------*/

.switch-btn-holder {
    display: flex;
    align-items: center;
    margin: 12px 0;
}

.switch-btn-holder > .label {
    margin: 0 12px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #c255a0;
}

input:focus + .slider {
    box-shadow: 0 0 1px #c255a0;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/*------switch css--------*/

.btn-group > .job-actions-btn {
    display: flex;
    align-items: baseline;
    width: 100%;
    margin: 0 9px
}

.btn-group > .job-actions-btn > .btn-holder {
    display: flex;
    width: 100%;
}

.btn-group > .job-actions-btn > .btn-holder > .act-btn {
    border: 1px solid #c355a0;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-group > .job-actions-btn > .btn-holder > .act-btn.accept-btn {
    border-radius: 50% 0 0 50%;
    padding: 6px 5px 6px 15px;
}

.btn-group > .job-actions-btn > .btn-holder > .act-btn.reject-btn {
    border-radius: 0 50% 50% 0;
    padding: 6px 15px 6px 5px;
}

.btn-group > .job-actions-btn > .btn-holder > .act-btn.accept-btn:hover {
    background: #57c355;
    border: 1px solid #dde6e7;
}

.btn-group > .job-actions-btn > .btn-holder > .act-btn.reject-btn:hover {
    background: #db4272;
    border: 1px solid #dde6e7;
}

.btn-group > .job-actions-btn > .btn-holder > .act-btn:hover .check-ic {
    color: white;
}

ul > .uli {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-user-modify: read-write;
}

.card-header.cover-photo > .rounded-circle.isJobOpen {
    border: 3px solid #c255a0 !important;
    box-shadow: 0 3px 13px 1px #f199d5;
    transition: border 2s ease-in-out;
}

.spinner-holder {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.viewAll-holder {
    display: flex;
    width: 100%;
    justify-content: center;
}

.viewAll-holder > .view-all {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.viewAll-holder > .view-all > .label {
    display: flex;
    margin: 0 9px;
}

.job-apply > .apply-btn {
    width: 100%;
    height: 50px;
    cursor: pointer;
    line-height: 50px;
    border-radius: 3px;
    border: none;
    box-shadow: none;
    font-size: 15px;
    color: #ffffff;
    font-weight: 700;
    padding: 0 10px;
    background-color: #C255A0;
    transition: all 0.3s ease 0s;
}

.maritalStatus .list {
    height: 150px;
    overflow-y: auto;
}

.maritalStatus .list::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    z-index: 0;
}

.maritalStatus .list::-webkit-scrollbar * {
    background: transparent;
}

.maritalStatus .list::-webkit-scrollbar-thumb {
    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: #666 !important;
    /*-webkit-box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.05), inset 1px 1px 0 rgba(0, 0, 0, 0.05);*/
}

.citySelection .list {
    height: 150px;
    overflow-y: auto;
}

.citySelection .list::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    z-index: 0;
}

.citySelection .list::-webkit-scrollbar * {
    background: transparent;
}

.citySelection .list::-webkit-scrollbar-thumb {
    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: #666 !important;
    /*-webkit-box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.05), inset 1px 1px 0 rgba(0, 0, 0, 0.05);*/
}

.about-us-vid {
    background-image: linear-gradient(45deg, #c355a0cc, #3fa3aee6);
    width: 100%;
    height: 400px;
}

.section-filter.hide {
    height: 0;
    transition: height 0.5s ease-in;
}


.section-filter.show {
    height: 135px;
    transition: height 0.5s ease-in;

}

.section-filter-form.show {
    display: block;
    transition: display 0.2s ease-in;
}

.section-filter-form.hide {
    display: none;
    transition: display 0.2s ease-out;
}

.get-section-filter-arrow-down {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.job-search-bg {
    background-color: #2c9aa766;
    /*background-image: url(../content/images/banner/bg_image.jpeg);*/
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.social-share {
    border-radius: 4px;
    padding: 8px;
}

.social-share .social-share-btn {
    margin: 0 4px;
}

.social-share .social-share-btn .share-icons {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.employer-rate > .star {
    display: flex;
    align-items: center;
}

.employer-rate > .star > .text {
    color: black;
    margin: 0 6px;
}

.star label {
    display: inherit;
}

.rating-average {
    display: flex;
    justify-content: flex-end;
    align-items: end;
}

.reactQuill .nice-select {
    display: none;
}

.iframeBody {
    display: flex;
    width: 100%;
    /*height: 1rem;*/
    max-height: 100vh;
    min-height: 100vh;
    height: -webkit-fill-available;
}

.slick-slider {
    display: block !important;
}

.single-job .job-info .job-info-inner .job-info-top .employer-name div {
    color: #666 !important;
}

/*---comment----*/
.widget .panel-body {
    padding: 0px;
}

.widget .list-group {
    margin-bottom: 0;
}

.widget .panel-title {
    display: inline
}

.widget .label-info {
    float: right;
}

.widget li.list-group-item {
    border-radius: 0;
    border: 0;
    border-top: 1px solid #ddd;
}

.widget li.list-group-item:hover {
    background-color: rgba(86, 61, 124, .1);
}

.widget .mic-info {
    color: #666666;
    font-size: 11px;
}

.widget .act {
    margin-top: 5px;
    text-align: end
}

.widget .comment-text {
    font-size: 12px;
}

.widget .btn-block {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.btn-xs {
    padding: 1px 5px;
}

.panel {
    width: 100%;
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}

.panel-default {
    border-color: #ddd;
}

.panel-default > .panel-heading {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
}

.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
}

/*---comment----*/

/*------------------------------ overflow ------------------------*/
/*.ov-des:hover {
    overflow-y: overlay;
}*/

.ov-des::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    z-index: 0;
}

.ov-des::-webkit-scrollbar * {
    background: transparent;
}

.ov-des::-webkit-scrollbar-thumb {
    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: #666 !important;
    /*-webkit-box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.05), inset 1px 1px 0 rgba(0, 0, 0, 0.05);*/
}

/*------------------------------ overflow ------------------------*/

span.fail {
    background-color: #e75743;
}

span.success {
    background-color: #5eca69;
}

.modal-map-holder {
    height: 400px;
    position: relative;
    display: flex;
    border-radius: 1rem;
    border: 1px solid #c355a0;
    overflow: hidden;
}