.centerDiv{
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
}
.head {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4rem;
    background-color: rgb(60, 51, 78);
    color: #fff;
}

.upperPart {
    padding: 20px;
    width: 100%;
    display: flex;
}

.chart1 {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.text {
    display: flex;
    justify-content: center;
    padding-top: 10px
}

.admin-dashboard  h2{
    font-size: 20px;
}