/*----------------------------------------*/
/*  09. Job Search CSS
/*----------------------------------------*/
@import "variabls";

.job-search-wrap{
    padding: 25px 25px 20px;
    background: rgba(0,0,0,.4);
    // Responsive
    @media #{$large-mobile}{
        padding: 25px 15px 20px;
    }
}

.job-search-wrap-two{
    .job-search-form{
        border: 1px solid #e2e4ea;
        background: #fff;
        border-radius: 3px;
        form{
            display: flex;
            position: relative;
            width: 100%;
            padding: 10px 0;
            // Responsive
            @media #{$large-mobile}{
                flex-wrap: wrap;
                padding: 10px 20px
            }
            .single-field-item{
                position: relative;
                flex: 0 0 37%;
                max-width: 37%;
                padding: 0 30px;
                height: 100%;
                margin-bottom: 0;
                // Responsive
                @media #{$desktop-device}{
                    padding: 0 15px;
                }
                @media #{$large-mobile}{
                    padding: 10px 0px;
                    flex: 0 0 100%;
                    max-width: 100%;
                }
                i{
                    position: static;
                    line-height: inherit;
                }
                label{
                    display: block;
                    font-size: 16px;
                    color: $heading-color-two;
                    font-weight: 600;
                    text-transform: capitalize;
                    margin: 0;
                }
                input{
                    width: 100%;
                    border: none;
                    color: #999;
                    font-size: 15px;
                    font-weight: 400;
                    padding: 0;
                    height: auto;
                    line-height: inherit;
                }
                .btn-action-location{
                    position: absolute;
                    top: 20px;
                    right: 15px;
                    //Responsive
                    @media #{$large-mobile}{
                        top: 30px;
                    }
                }
                &:first-child{
                    &::after{
                        position: absolute;
                        content: "";
                        height: 70%;
                        width: 1px;
                        background-color: #e2e4ea;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        @media #{$large-mobile}{
                            display: none;
                        }
                    }
                    //Responsive
                    @media #{$large-mobile}{
                        border-bottom: 1px solid #e2e4ea;
                    }
                }
                
            }
            .submit-btn{
                flex: 0 0 26%;
                max-width: 26%;
                padding: 0 10px 0 0;
                @media #{$large-mobile}{
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding: 10px 0px;
                }
                button{
                    height: 100%;
                }
            }
        }
    }
}
.job-search-wrap-three{
    .job-search-form{
        form{
            display: flex;
            position: relative;
            width: 100%;
            padding: 10px 0;
            // Responsive
            @media #{$large-mobile}{
                flex-wrap: wrap;
                padding: 0px
            }
            .single-field-item{
                position: relative;
                flex: 0 0 44%;
                max-width: 44%;
                padding: 17px 25px 19px;
                border: 1px solid #d0d0d0;
                height: 100%;
                border-radius: 3px 0 0 3px;
                background: $white;
                margin-bottom: 0;
                // Responsive
                @media #{$large-mobile}{
                    padding: 17px 25px 19px;
                    flex: 0 0 100%;
                    max-width: 100%;
                }
                i{
                    position: static;
                    line-height: inherit;
                }
                label{
                    display: block;
                    font-size: 18px;
                    color: $heading-color-two;
                    font-weight: 600;
                    text-transform: capitalize;
                    margin: 0;
                }
                input{
                    width: 100%;
                    border: none;
                    color: #999;
                    font-size: 15px;
                    font-weight: 400;
                    padding: 0;
                    height: auto;
                    line-height: inherit;
                }
                .btn-action-location{
                    position: absolute;
                    top: 35px;
                    right: 15px;
                    //Responsive
                    @media #{$large-mobile}{
                        top: 30px;
                    }
                }
                &.location{
                    flex: 0 0 31%;
                    max-width: 31%;
                    border-radius: 0 3px 3px 0;
                    border-left: none;
                    // Responsive
                    @media #{$large-mobile}{
                        flex: 0 0 100%;
                        max-width: 100%;
                        border-left: 1px solid #d0d0d0;
                        margin-top: 10px;
                    }
                }
                
            }
            .submit-btn{
                flex: 0 0 25%;
                max-width: 25%;
                padding: 0 0 0 10px;
                @media #{$large-mobile}{
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding: 10px 0px;
                }
                button{
                    height: 100%;
                }
            }
        }
    }
}


.single-field-item{
    height: 50px;
    background-color: $white;
    position: relative;
    border-radius: 3px;
    margin-bottom: 10px;
    i{
        position: absolute;
        left: 20px;
        top: 0;
        line-height: 50px;
    }
    input{
        height: 50px;
        line-height: 50px;
        width: 100%;
        padding: 0 20px 0 45px;
        border: none;
        box-shadow: none;
        background: 0 0;
        color: #333;
        font-weight: 600;
    }
    .nice-select{
        height: 50px;
        line-height: 50px;
        border-radius: 3px;
        & .list{
            max-height: 250px;
            overflow-y: scroll;
            // Responsive
            @media #{$desktop-device}{
                max-height: 210px;
            }
        }
    }
    .btn-action-location{
        position: absolute;
        right: 45px;
        line-height: 50px;
        cursor: pointer;
    }
}

